import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { BillingRedirectReasons } from '../../../../_shared/models/billingv2/billing-redirect-reasons.enum';
import { CustomerBillingInfo } from '../../../../_shared/models/billingv2/customer-billing-info-request.model';
import { UpdateSubscriptionQuantityRequestModel } from '../../../../_shared/models/billingv2/modify-seats-request';
import { Subscription } from '../../../../_shared/models/billingv2/subscription.model';

export const SubscriptionActions = createActionGroup({
  source: 'Billing',
  events: {
    Init: props<{ subscription: Subscription }>(),
    'Request Update Subscription Quantity Url': props<{
      updateSubscriptionQuantityRequest: UpdateSubscriptionQuantityRequestModel;
    }>(),
    'Request Update Subscription Quantity Url Success': props<{ redirectUrl: string }>(),
    'Request Update Subscription Quantity Url Failure': props<{ error: unknown; customMessage: string }>(),
    'Open Manage Seats Dialog': emptyProps(),
    'Request Payment Method Update Url': props<{ customerBillingInfo: CustomerBillingInfo }>(),
    'Request Payment Method Update Url Success': props<{ redirectUrl: string }>(),
    'Request Payment Method Update Url Failure': props<{ error: unknown; customMessage: string }>(),
    'Request Billing Portal Url': emptyProps(),
    'Request Billing Portal Url Success': props<{ redirectUrl: string }>(),
    'Request Billing Portal Url Failure': props<{ error: unknown; customMessage: string }>(),
    'Request Update Subscription Url': emptyProps(),
    'Request Update Subscription Url Success': props<{ redirectUrl: string }>(),
    'Request Update Subscription Url Failure': props<{ error: unknown; customMessage: string }>(),
    'Request Resume Subscription Url': props<{ customerBillingInfo: CustomerBillingInfo }>(),
    'Request Resume Subscription Url Success': props<{ redirectUrl: string }>(),
    'Request Resume Subscription Url Failure': props<{ error: unknown; customMessage: string }>(),
    'Request Reactivate Subscription Url': emptyProps(),
    'Request Reactivate Subscription Url Success': props<{ redirectUrl: string }>(),
    'Request Reactivate Subscription Url Failure': props<{ error: unknown; customMessage: string }>(),
    'Request Cancel Subscription Url': emptyProps(),
    'Request Cancel Subscription Url Success': props<{ redirectUrl: string }>(),
    'Request Cancel Subscription Url Failure': props<{ error: unknown; customMessage: string }>(),
    'Auto Increment Subscription Quantity': props<{ quantity: number }>(),
    'Auto Increment Subscription Quantity Success': props<{ subscription: Subscription }>(),
    'Auto Increment Subscription Quantity Failure': props<{ error: unknown; customMessage: string }>(),
    'Auto Increment Subscription Quantity Billing Page': props<{ quantity: number }>(),
    'Auto Increment Subscription Quantity Billing Page Success': props<{ subscription: Subscription }>(),
    'Auto Increment Subscription Quantity Billing Page Failure': props<{ error: unknown; customMessage: string }>(),
    'Get Updated Subscription': emptyProps(),
    'Get Updated Subscription Success': props<{ subscription: Subscription }>(),
    'Get Updated Subscription Failure': props<{ error: unknown; customMessage: string }>(),
    'Cancel Subscription': emptyProps(),
    'Cancel Subscription Success': props<{ subscription: Subscription }>(),
    'Cancel Subscription Failure': props<{ error: unknown; customMessage: string }>(),
    'Check For Banners': emptyProps(),
    'Open Validate Address Dialog': props<{ billingRedirectReasons: BillingRedirectReasons }>(),
    'Open Validate Address Dialog Success': emptyProps(),
    'Open Validate Address Dialog Failure': props<{ error: unknown; customMessage: string }>(),
    'Trigger Payment Added Segment Event': emptyProps(),
    'Trigger Subscription Cancelled Segment Event': emptyProps(),
    'Go To Billing Manage Seats': emptyProps(),
  },
});
