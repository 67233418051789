import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import type { AccessToken } from '../../_shared/models/auth/access-token';

const KEY = 'ACCESS-TOKEN';
const ENTITLEMENTS_KEY = 'ENTITLEMENTS-TOKEN';

export function tokenGetter(): string | null {
  return sessionStorage.getItem(KEY) ?? localStorage.getItem(KEY);
}

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private jwtHelperService: JwtHelperService) {}

  decodeToken<T>(token?: string): T {
    return this.jwtHelperService.decodeToken<T>(token);
  }

  getAccessToken(): string | null {
    return tokenGetter();
  }

  getAccessTokenDecoded(): AccessToken {
    return this.jwtHelperService.decodeToken(this.getAccessToken());
  }

  setAccessToken(value: string, useSessionStorage = false) {
    this.setToken(KEY, value, useSessionStorage);
  }

  removeAccessToken() {
    this.removeToken(KEY);
  }

  isExpired(token: { exp: number }): boolean {
    return token?.exp * 1000 < Date.now();
  }

  private getToken(key: string): string | null {
    return sessionStorage.getItem(key) ?? localStorage.getItem(key);
  }

  getEntitlementsToken(): string | null {
    return this.getToken(ENTITLEMENTS_KEY);
  }

  setEntitlementsToken(value: string, useSessionStorage = false) {
    this.setToken(ENTITLEMENTS_KEY, value, useSessionStorage);
  }

  removeEntitlementsToken() {
    this.removeToken(ENTITLEMENTS_KEY);
  }

  private setToken(key: string, value: string, useSessionStorage = false) {
    if (!useSessionStorage) localStorage.setItem(key, value);
    else sessionStorage.setItem(key, value);
  }

  private removeToken(key: string) {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  }
}
