import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { environment } from '@ninety/ui/web/environments';

import { FeatureFlagFacade } from '../../_state/app-entities/feature-flag/feature-flag-state.facade';

@Injectable({
  providedIn: 'root',
})
export class EntitlementsService {
  private apiBasePath = environment.entitlementsApiOrigin;
  private entitlementsEnabled$: Observable<boolean> = this.flags.getFlag(FeatureFlagKeys.commercialModelEntitlements);

  constructor(private readonly http: HttpClient, private readonly flags: FeatureFlagFacade) {}

  getEntitlementsToken(authToken: string): Observable<string> {
    return this.entitlementsEnabled$.pipe(
      switchMap(enabled => {
        if (!enabled) {
          return of('');
        }
        const url = `${this.apiBasePath}/v1/entitlements-token`;
        const headers = this.getHeaders(authToken);
        return this.http.get<string>(url, { headers });
      })
    );
  }

  private getHeaders(authToken: string): HttpHeaders {
    return new HttpHeaders({
      'content-type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${authToken}`,
    });
  }
}
