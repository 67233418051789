import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { map } from 'rxjs';

import { TerraFormFieldModule, TerraSelectModule, TerraSwitchModule } from '@ninety/terra';
import { CompanyService } from '@ninety/ui/legacy/core/services/company.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { MeasurableWeeklyStartDay } from '@ninety/ui/legacy/shared/models/company/company';
import { CompanySettings } from '@ninety/ui/legacy/shared/models/company/company-settings';
import { MeasurableWeeklyStartDays } from '@ninety/ui/legacy/shared/models/company/measurable-weekly-start-days';
import {
  PeriodInterval,
  ScorecardPeriodToHideCurrentPeriod,
} from '@ninety/ui/legacy/shared/models/scorecard/period-interval.enum';
import { HideCurrentPeriodOnScorecard } from '@ninety/ui/legacy/shared/models/scorecard/show-current-period-on-scorecard';
import { selectCompanySettings } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';

@Component({
  selector: 'ninety-scorecard-settings',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    LetDirective,

    MatTooltipModule,

    TerraSwitchModule,
    TerraFormFieldModule,
    TerraSelectModule,
  ],
  templateUrl: './scorecard-settings.component.html',
  styleUrls: ['./scorecard-settings.component.scss'],
})
export class ScorecardSettingsComponent {
  measurableWeeklyStartDays = MeasurableWeeklyStartDays;
  PeriodInterval = PeriodInterval;
  hideCurrentPeriodOnScorecardCopy: HideCurrentPeriodOnScorecard;
  ScorecardPeriodToHideCurrentPeriod = ScorecardPeriodToHideCurrentPeriod;
  settings$ = this.store.select(selectCompanySettings).pipe(
    map(settings => ({
      // TODO: Remove defaults after DEV-16528
      measurableShowCurrentPeriod: true,
      measurableShowDefaultGoal: true,
      measurableShowOwner: true,
      ...cloneDeep(settings),
    }))
  );
  language$ = this.store.select(selectLanguage);

  protected kpiStatusDescription$ = this.language$.pipe(
    map(
      lang =>
        `Green: On-track - Reached ${lang.measurable.goal} over the last 3 periods.\n` +
        `Yellow: At Risk - Missed ${lang.measurable.goal} at least 1x over the last 3 periods.\n` +
        `Red: Off-track - Missed ${lang.measurable.goal} all 3x over the last 3 periods.`
    )
  );

  protected vm$ = {
    settings: this.settings$,
    language: this.language$,
    kpiStatusDescription: this.kpiStatusDescription$,
  };

  constructor(public stateService: StateService, private companyService: CompanyService, private store: Store) {}

  startDayIsSame(option: MeasurableWeeklyStartDay, value: MeasurableWeeklyStartDay): boolean {
    return option.offset === value.offset;
  }

  updateSettings<K extends keyof CompanySettings>(prop: K, value: CompanySettings[K]): void {
    this.companyService.updateSettingsWithKeyValue(prop, value).subscribe();
  }
}
