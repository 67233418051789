import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import Pubnub from 'pubnub';
import {
  BehaviorSubject,
  catchError,
  combineLatestWith,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  exhaustMap,
  filter,
  forkJoin,
  from,
  interval,
  map,
  Observable,
  of,
  retry,
  Subject,
  Subscription,
  switchMap,
  take,
  tap,
  throwError,
  withLatestFrom,
} from 'rxjs';

import { environment } from '@ninety/ui/web/environments';

// eslint-disable-next-line max-len
import { AccountChangePasswordDialogComponent } from '../../_shared/components/account-change-password-dialog/account-change-password-dialog.component';
import {
  ConfirmIdentityDialogComponent,
  ConfirmIdentityDialogData,
  ConfirmIdentityDialogStatus,
} from '../../_shared/components/confirm-identity-dialog/confirm-identity-dialog.component';
import { NinetySignupParams, SignUp } from '../../_shared/models/_shared/sign-up';
import { AuthModel } from '../../_shared/models/auth/auth-model';
import { AuthenticatedUserResponse } from '../../_shared/models/auth/authenticated-user-response';
import { LoginError } from '../../_shared/models/auth/login-error.enum';
import { RefreshToken } from '../../_shared/models/auth/refresh-token';
import type { ForgotPasswordStatus } from '../../_shared/models/enums/forgot-password-status';
import { FeatureFlagFacade } from '../../_state/app-entities/feature-flag/feature-flag-state.facade';
import { FeatureFlagKeys } from '../../_state/app-entities/feature-flag/feature-flag-state.model';
import { selectRawCurrentUser } from '../../_state/app-entities/users/users-state.selectors';
import { AuthActions } from '../../_state/app-global/auth/auth.actions';
import { CurrentPersonStateActions } from '../../_state/app-global/current-person/current-person.actions';
import { CookieService } from '../services/cookie.service';

import { AppLoadService } from './app-load.service';
import { EntitlementsService } from './entitlements.service';
import { CognitoMfaType, CognitoStates, IdentityProviderService } from './identity-provider.service';
import { NotifyService } from './notify.service';
import { PersonService } from './person.service';
import { SpinnerService } from './spinner.service';
import { StateService } from './state.service';
import { SessionStorageService, StorageService } from './storage.service';
import { TokenService } from './token.service';

const MIN_IN_MS = 60000;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private pubnubApi = 'api/v4/Pubnub';
  private pubnub: Pubnub;
  private listeners: Pubnub.ListenerParameters;
  private revokeLoginSession$ = new Subject<void>();

  sessionLogoutFlag$: Observable<boolean> = this.flags.getFlag(FeatureFlagKeys.sessionLogout);

  loginData$ = new BehaviorSubject<AuthenticatedUserResponse>(null);

  refreshSubscription = new Subscription();
  useSessionStorage = false;

  get isLoggedIn(): boolean {
    if (!!this.loginData$.value) {
      return true;
    }

    return !!this.tokenService.decodeToken();
  }

  currentUrl: string;

  constructor(
    private readonly appLoadService: AppLoadService,
    private readonly cookieService: CookieService,
    private readonly identityProviderService: IdentityProviderService,
    private readonly notifyService: NotifyService,
    private readonly personService: PersonService,
    private readonly store: Store,
    private readonly spinnerService: SpinnerService,
    private readonly stateService: StateService,
    private readonly tokenService: TokenService,
    private readonly flags: FeatureFlagFacade,
    private readonly datePipe: DatePipe,
    private readonly dialog: MatDialog,
    private readonly http: HttpClient,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly sessionStorageService: SessionStorageService,
    private readonly storage: StorageService,
    private readonly entitlementsService: EntitlementsService
  ) {
    this.identityProviderService.cognitoAuthError$.pipe(tap(() => this.logout())).subscribe();

    this.store
      .select(selectRawCurrentUser)
      .pipe(
        filter(cu => !!cu),
        distinctUntilChanged((cu1, cu2) => cu1?._id !== cu2?._id),
        tap(() => this.initRefreshInterval()),
        distinctUntilKeyChanged('personId'),
        withLatestFrom(this.sessionLogoutFlag$),
        switchMap(([cu, sessionLogoutFlag]) => {
          this.destroyPubnub();
          return sessionLogoutFlag ? this.initPubnub(cu.personId) : of(null);
        })
      )
      .subscribe();
    this.router.events.pipe(filter(ev => ev instanceof NavigationEnd)).subscribe({
      next: (ev: NavigationEnd) => {
        this.currentUrl = ev.url;
      },
    });

    this.revokeLoginSession$
      .pipe(
        exhaustMap(() => this.sessionLogoutFlag$),
        filter(sessionLogoutFlag => !!sessionLogoutFlag),
        tap(() => this.logout())
      )
      .subscribe();
  }

  private destroyPubnub() {
    if (this.listeners) {
      this.pubnub?.removeListener(this.listeners);
      this.listeners = undefined;
    }
    this.pubnub?.unsubscribeAll();
  }

  private initPubnub(personId: string) {
    this.pubnub = new Pubnub({
      publishKey: environment.pubnubPublishKey,
      subscribeKey: environment.pubnubSubscribeKey,
      uuid: personId,
      presenceTimeout: environment.pubnubPresenceExpiration,
      heartbeatInterval: environment.heartbeatInterval,
    });

    this.listeners = {
      status: (status: Pubnub.StatusEvent) => {
        if (status.category === Pubnub.CATEGORIES.PNBadRequestCategory) {
          // console.error('auth', status);
        } else if (status.category === Pubnub.CATEGORIES.PNAccessDeniedCategory) {
          this.subscribeToSessionChannel().pipe(retry(3)).subscribe();
        }
      },
      message: (message: Pubnub.MessageEvent) => {
        if (message?.message === 'revokeSession') this.revokeLoginSession$.next();
      },
    };

    this.pubnub.addListener(this.listeners);
    return this.subscribeToSessionChannel(personId);
  }

  private subscribeToSessionChannel(personId = this.stateService.currentPerson$.value._id): Observable<string> {
    const sessionChannel = `session-${personId}`;
    return this.http
      .post<string>(`${this.pubnubApi}/LoginSession/Grant`, {
        channels: [sessionChannel],
      })
      .pipe(
        tap(response => this.pubnub.setToken(response)),
        tap(() =>
          this.pubnub.subscribe({
            channels: [sessionChannel],
            withPresence: false,
          })
        )
      );
  }

  initRefreshInterval() {
    this.refreshSubscription.unsubscribe();

    this.refreshSubscription = new Subscription();
    this.refreshSubscription.add(
      interval(MIN_IN_MS * 25)
        .pipe(switchMap(() => this.refreshAccessToken()))
        .subscribe()
    );
  }

  refreshAccessToken(userId = '', useSessionStorage = false): Observable<RefreshToken> {
    this.useSessionStorage = useSessionStorage;
    //switching companies
    if (userId) this.cookieService.setNinetyUserIdCookie(userId);

    return this.http.get<RefreshToken>('/api/v4/Refresh').pipe(
      tap(() => this.setLastLoginDate()),
      tap(({ tokens }) => {
        if (tokens?.access) this.tokenService.setAccessToken(tokens.access);
      }),
      catchError((err: unknown) => {
        // console.error('There was a problem fetching refresh tokens: ', err);

        return throwError(err);
      })
    );
  }

  setLastLoginDate() {
    localStorage.setItem('lastLoginDate', this.datePipe.transform(Date.now(), 'yyyy-MM-dd'));
  }

  private removeAccessToken(): void {
    this.tokenService.removeAccessToken();
  }

  private removeEntitlementsToken(): void {
    this.tokenService.removeEntitlementsToken();
  }

  // signup =====

  challengeEmail(email: string): Observable<ConfirmIdentityDialogStatus | string | void | { closedFromBtn: true }> {
    this.spinnerService.stop();

    return this.dialog
      .open<
        ConfirmIdentityDialogComponent,
        ConfirmIdentityDialogData,
        ConfirmIdentityDialogStatus | string | void | { closedFromBtn: true }
      >(ConfirmIdentityDialogComponent, {
        disableClose: true,
        // ConfirmIdentity dialog is also used when logging in to submit MFA challenge. Here it is used for email challenge
        data: { email: email, status: ConfirmIdentityDialogStatus.VerifyEmail },
      })
      .afterClosed()
      .pipe(
        tap(resp => {
          if ((resp as { closedFromBtn: true })?.closedFromBtn) {
            this.spinnerService.stop();
          } else {
            this.spinnerService.start();
          }
        })
      );
  }

  signupWithIdp(data: SignUp): Observable<string> {
    // signUp() fails if user exists, even if email challenge incomplete
    return this.identityProviderService.signUp(data).pipe(
      switchMap(() => this.challengeEmail(data.email)),
      switchMap(() => this.loginToIdp(data))
    );
  }

  signupWithNinety(signupParams: Pick<NinetySignupParams, 'idToken'>): Observable<AuthenticatedUserResponse> {
    return this.http
      .post<AuthenticatedUserResponse>('/api/v4/Signup', signupParams)
      .pipe(switchMap(() => this.loginToNinety(signupParams, true)));
  }

  continueSignupWithNinety(signupParams: NinetySignupParams): Observable<AuthenticatedUserResponse> {
    return this.http.post<AuthenticatedUserResponse>('/api/v4/Signup', { ...signupParams, personOnly: true });
  }

  // login ======

  loginToIdp(creds: AuthModel): Observable<string> {
    return this.identityProviderService.signIn(creds.email, creds.password).pipe(
      catchError((err: unknown) => {
        // Couldn't find what cognito error type has 'code' prop
        if ((err as { code?: string })?.code === CognitoStates.confirmEmail) {
          return this.challengeEmail(creds.email).pipe(switchMap(_ => this.loginToIdp(creds))); // recurse until challenge accepted
        }

        // Because the error types aren't defined for cognito, added a fallback check
        // for any object with a 'message' property.
        if (err instanceof Error || (err instanceof Object && 'message' in err)) {
          if (err.message === CognitoStates.forcePasswordChange) {
            return this.openChangePasswordDialog().pipe(
              switchMap(newPassword =>
                this.loginToIdp({
                  email: creds.email,
                  password: newPassword,
                })
              )
            );
          }
        }

        throw err;
      })
    );
  }

  loginToIdpWithMigration(creds: { email: string; password: string }): Observable<string> {
    return this.identityProviderService.signInWithMigration(creds.email, creds.password);
  }

  loginToNinety(
    creds:
      | ({ idToken: string } & AuthModel) // social login
      | ({ email: string; password: string } & AuthModel), // username password login
    skipRedirect = false
  ): Observable<AuthenticatedUserResponse> {
    return this.http
      .post<AuthenticatedUserResponse>('/api/v4/Login', creds, {
        withCredentials: true,
      })
      .pipe(
        tap(resp => {
          if (resp?.tokens?.access) {
            this.tokenService.setAccessToken(resp.tokens.access);
          }
        }),
        switchMap(resp => {
          if (resp?.tokens?.access) {
            return this.entitlementsService.getEntitlementsToken(resp.tokens.access).pipe(
              tap(entitlementsToken => {
                if (entitlementsToken) {
                  this.tokenService.setEntitlementsToken(entitlementsToken);
                }
              }),
              map(() => resp) // Pass the response along the chain
            );
          }
          return of(resp);
        }),
        catchError((err: unknown) => {
          if (
            (err as { error?: { errorMessage?: string } })?.error?.errorMessage
              ?.toLowerCase()
              ?.includes(LoginError.NO_PERSON.toLowerCase())
          ) {
            const email = creds.idToken
              ? this.tokenService.decodeToken<{ email: string }>(creds.idToken).email
              : creds.email;
            this.router.navigate(['/sign-up'], email ? { queryParams: { email } } : undefined);
            throw new Error((err as { error?: { errorMessage?: string } })?.error?.errorMessage);
          }
          throw err;
        }),
        tap(resp => {
          this.setLastLoginDate();
          this.loginData$.next(resp);
          this.store.dispatch(CurrentPersonStateActions.loginUser({ person: resp.person }));
        }),
        combineLatestWith(this.identityProviderService.cognitoUser),
        switchMap(([resp, cognitoUser]) => {
          let companyMfaRequired = false;

          if (!resp.tokens?.access && !resp.mfaSetupRequired) {
            // login from signup does not require access token
            return of(resp);
          } else if (resp.tokens?.access) {
            companyMfaRequired =
              this.tokenService.decodeToken<{ companyMfaRequired?: boolean }>(resp.tokens.access)?.companyMfaRequired ??
              false;
          }

          // determine if user needs to set up MFA and require them to do so if necessary
          if (!cognitoUser) throw new Error('No cognito user found');

          const federatedUser = !!cognitoUser.attributes.identities; // this key/val is only present on fed'd users
          const verifiedTel = !!cognitoUser.attributes.phone_number_verified; // we can skip if user already verified
          const mfaRequired = !!resp.mfaSetupRequired || !!companyMfaRequired;
          const userMfa = cognitoUser.preferredMFA !== CognitoMfaType.NONE;

          if (mfaRequired && !federatedUser && !verifiedTel) {
            this.notifyService.notify('Please set up MFA and re-login to continue.');
            return this.openVerifyDialog({
              email: cognitoUser.attributes.email,
              personId: resp.person._id,
              status: ConfirmIdentityDialogStatus.InitNumber,
            }).pipe(
              switchMap(verifyStatus => {
                this.spinnerService.start();
                if (
                  verifyStatus === ConfirmIdentityDialogStatus.ConfirmMFA ||
                  verifyStatus === ConfirmIdentityDialogStatus.ConfirmNumber
                ) {
                  // relogin now that MFA is setup
                  return this.loginToNinety(creds, false).pipe(map(() => of(null)));
                } else {
                  this.logout();
                  return of(null);
                }
              })
            );
          } else if (mfaRequired && !federatedUser && verifiedTel && !userMfa) {
            // turn on mfa if company requires and user already has tel verified but not turned mfa on
            return this.identityProviderService.setMfaType(CognitoMfaType.SMS).pipe(map(_ => of(resp)));
          } else {
            return of(resp);
          }
        }),
        filter((resp: AuthenticatedUserResponse) => !!resp),
        tap(() => {
          this.store.dispatch(AuthActions.logIn());
        }),
        switchMap((resp: AuthenticatedUserResponse) => {
          if (!!resp.tokens?.access && !!resp.userCount && !resp.mfaSetupRequired) {
            return from(this.appLoadService.initializeCompanyUser(resp.tokens)).pipe(
              tap(() => {
                if (!skipRedirect) this.loginToNinetyRedirect();
              }),
              map(() => resp)
            );
          } else if (skipRedirect) {
            return of(resp);
          } else if (resp.userCount === 0) {
            // User count is zero when they have been deleted from all their companies
            this.router.navigate(['/create-company']);
            if (this.currentUrl?.startsWith('/login'))
              this.notifyService.showError('No users found, please create a company.');
          }
          return of(resp);
        })
      );
  }

  private loginToNinetyRedirect(urlOverride?: string): void {
    const userRedirect =
      this.route.snapshot.queryParamMap.get('redirectUrl') || this.storage.get('redirectUrl') || '/home';
    const url = urlOverride ? urlOverride : userRedirect;
    this.storage.delete('redirectUrl');
    this.router.navigate([url]);
  }

  logout(): void {
    this.sessionStorageService.delete('lastAccessedTeamId');

    this.refreshSubscription.unsubscribe();
    this.removeAccessToken();
    this.removeEntitlementsToken();
    this.cookieService.deleteAll();

    // Needs to run before navigating to /login to prevent hitting route guard
    this.loginData$.next(null);
    this.store.dispatch(AuthActions.logOut());

    forkJoin([
      this.identityProviderService.signOut(),
      this.http.get('/api/v4/Logout'),
      this.http.get('/api/v4/LogoutDomain'),
    ])
      .pipe(take(1))
      .subscribe({
        next: () => {
          window.location.href = '/login';
          this.stateService.onLogout();
          this.spinnerService.stop();
        },
        error: () => {
          window.location.href = '/login';
          this.stateService.onLogout();
          this.spinnerService.stop();
        },
      });
  }

  forgotPassword(email: string): Observable<ForgotPasswordStatus> {
    return this.personService.forgotPassword(email);
  }

  forgotPasswordSubmit(params: { email: string; code: string; newPassword: string }): Observable<string> {
    return this.personService.forgotPasswordSubmit(params);
  }

  openVerifyDialog(data: ConfirmIdentityDialogData) {
    this.spinnerService.stop();
    return this.dialog
      .open<ConfirmIdentityDialogComponent, ConfirmIdentityDialogData, { closedFromBtn: true } | string | void>(
        ConfirmIdentityDialogComponent,
        {
          data: data,
          disableClose: true,
        }
      )
      .afterClosed()
      .pipe(tap(_ => this.spinnerService.start()));
  }

  private openChangePasswordDialog() {
    this.spinnerService.stop();
    return this.dialog
      .open<AccountChangePasswordDialogComponent>(AccountChangePasswordDialogComponent, {
        data: { isTempPassword: true },
      })
      .afterClosed();
  }
}
