<h1 mat-dialog-title>Enter the email confirmation code and new password for {{ email }}</h1>
<div mat-dialog-content>
  <form name="forgotPasswordSubmit" [formGroup]="forgotPasswordSubmitGroup">
    <terra-form-field class="form-fields">
      <terra-text-input
        data-cy="forgot-password-submit-dialog_code"
        autocomplete="one-time-code"
        placeholder="Code"
        [formControl]="codeControl"
        required />
    </terra-form-field>
    <div class="form-fields">
      <mat-form-field class="_terra-migration-approved-override--mat-form-field" appearance="outline">
        <input
          data-cy="forgot-password-submit-dialog_new-password"
          matInput
          autocomplete="new-password"
          placeholder="New password"
          type="password"
          class="fs-mask"
          [formControl]="newPasswordControl" />
      </mat-form-field>
    </div>
    <mat-form-field class="form-fields _terra-migration-approved-override--mat-form-field" appearance="outline">
      <input
        data-cy="forgot-password-submit-dialog_confirm-new-password"
        matInput
        autocomplete="new-password"
        placeholder="Confirm new password"
        type="password"
        class="fs-mask"
        [formControl]="confirmNewPasswordControl" />
    </mat-form-field>
    <mat-list>
      <mat-error *ngIf="isCodeExpired">Code expired -- request new code</mat-error>
      <mat-error *ngIf="forgotPasswordSubmitGroup.errors?.passwordMismatch">Password does not match</mat-error>
      <mat-error *ngIf="forgotPasswordSubmitGroup.errors?.passwordLength"
        >Password must be 8 or more characters</mat-error
      >
      <mat-error *ngIf="forgotPasswordSubmitGroup.errors?.missingLowerAlpha"
        >Password needs a lowercase alphabetical character (a-z).</mat-error
      >
      <mat-error *ngIf="forgotPasswordSubmitGroup.errors?.missingNumeric">Password needs a number (0-9)</mat-error>
      <mat-error *ngIf="forgotPasswordSubmitGroup.errors?.missingSpecial">Password needs a special character</mat-error>
      <mat-error *ngIf="forgotPasswordSubmitGroup.errors?.invalidChars"
        >Invalid password characters: {{ forgotPasswordSubmitGroup.errors.invalidChars }}</mat-error
      >
    </mat-list>
  </form>
</div>
<div mat-dialog-actions class="submit-button-box" fxLayout="row" fxLayoutAlign="end center">
  <button data-cy="forgot-password-submit-dialog_close" mat-button mat-dialog-close>Close</button>
  <button
    data-cy="forgot-password-submit-dialog_request-new-code"
    mat-button
    [disabled]="(spinnerService.primary$ | async) || !email"
    (click)="sendForgotPasswordCode()">
    Request new code
  </button>
  <button
    data-cy="forgot-password-submit-dialog_submit"
    mat-button
    color="accent"
    [disabled]="
      forgotPasswordSubmitGroup.invalid ||
      (spinnerService.primary$ | async) ||
      !email ||
      isCodeExpired ||
      codeControl.value === expiredCode
    "
    (click)="forgotPasswordSubmit()">
    Submit
  </button>
</div>
