import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { PricingPlan } from '../../../_shared/models/billing-v3/pricing-plan';
// aliasing as ...V3 for now to avoid alias collision and since this is currently unused
import { SubscriptionConfiguration as SubscriptionConfigurationV3 } from '../../../_shared/models/billing-v3/subscription-configuration';
import { InvoiceV2 } from '../../../_shared/models/billingv2/InvoiceV2-response';
import { BillingCounts } from '../../../_shared/models/billingv2/billing-counts-response';
import { SubscriptionConfiguration } from '../../../_shared/models/billingv2/subscription-configuration.model';

export const BillingStateActions = createActionGroup({
  source: 'Billing',
  events: {
    Init: props<{ subscriptionConfigurations: SubscriptionConfiguration[] }>(),
    'Billing Init': emptyProps(),
    'Get Active Subscription Configurations': emptyProps(),
    'Get Active Subscription Configurations Success': props<{
      subscriptionConfigurations: SubscriptionConfiguration[];
    }>(),
    'Get Active Subscription Configurations Failure': props<{ error: unknown; customMessage: string }>(),
    'Get Company Billing Counts': emptyProps(),
    'Get Company Billing Counts Success': props<{ companyBillingCounts: BillingCounts }>(),
    'Get Company Billing Counts Failure': props<{ error: unknown; customMessage: string }>(),
    'Get Upcoming Invoice': emptyProps(),
    'Get Upcoming Invoice Success': props<{ invoice: InvoiceV2 }>(),
    'Get Upcoming Invoice Failure': props<{ error: unknown; customMessage: string }>(),

    /** Commercial Model */
    'Open Pricing Tier Dialog': emptyProps(),
    'Get Commercial Model Subscriptions': emptyProps(),
    'Get Commercial Model Subscriptions Success': props<{ pricingPlans: PricingPlan[] }>(),
  },
});
