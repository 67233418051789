import { AfterViewInit, DestroyRef, Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concat, filter, skip, take } from 'rxjs';

import { DashboardExpandCandidateDirective } from './dashboard-expand-candidate.directive';
import { DashboardExpandActions } from './dashboard-expand.actions';
import { DashboardExpandSelectors, EXPANDED_WIDGET_QUERY_PARAM_KEY } from './dashboard-expand.selectors';

/**
 * Transforms its host as the target of an expandable component.
 *
 * Expanded Flow
 * 1. `ExpandedWidgetActions.expand` is dispatched. Typically done by the ExpandCandidateDirective but anyone can dispatch.
 * 2. Target sets `expand` query param to the widget id
 * 3. Target reacts to changed query param and expands the widget
 *
 * Collapsed Flow
 * 1. `ExpandedWidgetActions.collapse` is dispatched. Typically done by the ExpandCandidateDirective but anyone can dispatch.
 * 2. Target sets `expand` query param to null
 * 3. Target reacts to changed query param and collapses the widget
 *
 * The explicit separation between steps 2 & 3 in both flows is intentional. It lets the target directive respond to other types
 * of navigation events that set the query param, such as the param being present on page load or use of the forward/back buttons.
 */
@Directive({
  selector: '[ninetyDashboardExpandTarget]',
  exportAs: 'ninetyDashboardExpandTarget',
  standalone: true,
})
export class DashboardExpandTargetDirective implements OnInit, AfterViewInit {
  /** The original parent of the expanded component. */
  private oldParent: ElementRef<HTMLElement> | null = null;

  /** The currently expanded widget. Null if no widget is expanded. */
  private expandedDirective: DashboardExpandCandidateDirective | null = null;

  /** Tracks all directives that have registered with this target. */
  private readonly candidateMap = new Map<string, DashboardExpandCandidateDirective>();

  constructor(
    private readonly renderer: Renderer2,
    private readonly hostElement: ElementRef,
    private readonly store: Store,
    private readonly destroyRef: DestroyRef,
    readonly actions$: Actions,
    readonly router: Router
  ) {
    actions$.pipe(ofType(DashboardExpandActions.expand), takeUntilDestroyed()).subscribe(({ widgetId }) => {
      router.navigate([], {
        queryParams: { [EXPANDED_WIDGET_QUERY_PARAM_KEY]: widgetId },
        queryParamsHandling: 'merge',
      });
    });

    actions$.pipe(ofType(DashboardExpandActions.collapse), takeUntilDestroyed()).subscribe(() => {
      router.navigate([], {
        queryParams: { [EXPANDED_WIDGET_QUERY_PARAM_KEY]: null },
        queryParamsHandling: 'merge',
      });
    });
  }

  ngOnInit(): void {
    // Add base class to the host element
    this.renderer.addClass(this.hostElement.nativeElement, 'expand-target');
    this.renderer.addClass(this.hostElement.nativeElement, 'expand-inactive');
  }

  ngAfterViewInit() {
    // Only expand on init, don't try to collapse on init
    // (which would make no sense - if there is no expanded widget on init, do nothing)
    const expandOnInit = this.store
      .select(DashboardExpandSelectors.expandedWidgetFromQueryParams)
      .pipe(take(1), filter(Boolean));

    // After the first emission, expand or collapse based on the query params
    const expandOrCollapseOnChange = this.store
      .select(DashboardExpandSelectors.expandedWidgetFromQueryParams)
      .pipe(skip(1));

    // Create obs flow and subscribe
    concat(expandOnInit, expandOrCollapseOnChange)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(widgetId => (widgetId ? this.expandFromId(widgetId) : this.collapse()));
  }

  /** Register a candidate with the target. Allows domains to pass just the key of the candidate VS the candidate itself. */
  register(candidate: DashboardExpandCandidateDirective): void {
    this.candidateMap.set(candidate.id, candidate);
  }

  /** Deregister a candidate with the target. Expected to be called during candidate destroy. */
  deregister(candidate: DashboardExpandCandidateDirective): void {
    this.candidateMap.delete(candidate.id);
  }

  /** Convenience getter for the expanded component's host element. */
  private get expandedElement(): HTMLElement | null {
    return this.expandedDirective?.host.nativeElement ?? null;
  }

  private isExpanded(): boolean {
    return !!this.expandedDirective;
  }

  private expandFromId(candidateId: string): void {
    const candidate = this.candidateMap.get(candidateId);
    if (!candidate) {
      // console.warn(`Candidate with ID ${candidateId} not found.`);
      return;
    }

    this.expand(candidate);
  }

  /**
   * Expand a component into the target.
   * Dispatch `ExpandedWidgetActions.expand` to trigger. Private to ensure that the query param state always matches the expanded
   * state.
   */
  private expand(candidate: DashboardExpandCandidateDirective): void {
    if (this.isExpanded()) {
      // console.warn('Another component is already expanded.');
      return;
    }

    this.expandedDirective = candidate;
    this.oldParent = this.renderer.parentNode(this.expandedElement);

    this.renderer.appendChild(this.hostElement.nativeElement, this.expandedElement);

    this.renderer.addClass(this.hostElement.nativeElement, 'expand-active');
    this.renderer.removeClass(this.hostElement.nativeElement, 'expand-inactive');

    this.renderer.addClass(this.expandedElement, 'expanded-host');
  }

  /** Collapse the currently expanded component. */
  private collapse(): void {
    if (!this.isExpanded()) {
      // console.warn('No component is currently expanded.');
      return;
    }

    this.renderer.appendChild(this.oldParent, this.expandedElement);

    this.renderer.removeClass(this.hostElement.nativeElement, 'expand-active');
    this.renderer.addClass(this.hostElement.nativeElement, 'expand-inactive');

    this.renderer.removeClass(this.expandedElement, 'expanded-host');

    this.expandedDirective = null;
    this.oldParent = null;
  }
}
