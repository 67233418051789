/* eslint-disable @angular-eslint/no-outputs-metadata-property,@angular-eslint/no-output-rename */
import { OverlayContainer } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, Input, TrackByFunction } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { LetDirective, PushPipe } from '@ngrx/component';
import { distinctUntilChanged, skip } from 'rxjs';

import { TerraAvatarModule, TerraQuickFilterModule, TerraSearchInputModule } from '@ninety/terra';
import { ManagerPick } from '@ninety/ui/legacy/state/app-entities/user-list/api/user-list.model';

import { SelectImplementationDirective } from '../chip-select/directives/select-implementation.directive';
import { FuseManagerPickDirective } from '../chip-select/services/fuse-manager-pick.directive';
import { ManagedOptions } from '../chip-select/services/fuse-search.service.model';

export type ManagerSelectCandidate = ManagerPick | 'all';

/**
 * User select styled after the "quick filter" component in the ninety design system. Supports fuzzy search and selection of a single user.
 * Based off a pick of the {@link UserListModel} type.
 *
 * @see {@link FuseManagerPickDirective}
 * @see {@link ManagerPick}
 */
@Component({
  selector: 'ninety-user-quick-filter',
  standalone: true,
  imports: [
    CommonModule,
    PushPipe,
    TerraAvatarModule,
    FormsModule,
    LetDirective,
    TerraQuickFilterModule,
    TerraSearchInputModule,
  ],
  templateUrl: './user-quick-filter.component.html',
  styleUrls: ['./user-quick-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    {
      directive: SelectImplementationDirective,
      inputs: ['readonly', 'disabled', 'id', 'noResultsText', 'selected: selectedUser'],
      outputs: ['selectedChange: selectedUserChange'],
    },
    {
      directive: FuseManagerPickDirective,
      inputs: ['ninetyFuseUser: users$', 'ninetyFuseSeatOptions: fuseOptions'],
    },
  ],
})
export class UserQuickFilterComponent implements AfterViewInit {
  protected readonly trackBy: TrackByFunction<ManagedOptions<ManagerPick>> = (_index, item) => item.value._id;

  protected optionListElement: Element;

  @Input() loading = false;

  protected _userSearchInput = '';

  constructor(
    protected readonly select: SelectImplementationDirective<ManagerSelectCandidate>,
    protected readonly fuseProvider: FuseManagerPickDirective,
    private readonly destroyRef: DestroyRef,
    private readonly _overlayContainer: OverlayContainer
  ) {
    select.multiple = false;
  }

  ngAfterViewInit() {
    this.fuseProvider.fuse.managedOptions$
      .pipe(
        distinctUntilChanged(),
        skip(1),
        // The option list is removed from the DOM when there is no data to display. Also, due to the observable nature of the data source,
        // the list might emit while the select is closed.
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.optionListElement?.scrollTo({ top: 0, behavior: 'smooth' }));
  }

  protected search(query: string) {
    this.fuseProvider.fuse.search({ query });
  }

  protected onSelectValue($event: ManagerPick) {
    const user = [$event];
    this.select.updateObserversOnValueChange(user);
  }

  protected onOpenChange(isOpen: boolean) {
    if (!isOpen) {
      this.fuseProvider.fuse.search({ query: null });
      this._userSearchInput = '';
    } else {
      // TERRATODO: Temp until scrollToTop() available
      if (!this.optionListElement) {
        const overlayContainerElement = this._overlayContainer.getContainerElement();
        this.optionListElement = overlayContainerElement.querySelector('.terra-select__options');
      }
    }
  }

  protected _handleSearchTermChanged($event: string) {
    this._userSearchInput = $event;
    this.search(this._userSearchInput);
  }
}
