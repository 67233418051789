import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TokenService } from '@ninety/ui/legacy/core/services/token.service';
import { environment } from '@ninety/ui/web/environments';

@Injectable()
export class EntitlementsInterceptor implements HttpInterceptor {
  private apiBasePaths = [environment.apiOrigin, environment.mazApiOrigin];

  constructor(private tokenService: TokenService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const shouldAddHeader = this.apiBasePaths.some(basePath => req.url.startsWith(basePath));

    if (!shouldAddHeader) {
      return next.handle(req);
    }

    const token = this.tokenService.getEntitlementsToken();
    if (!token) {
      return next.handle(req);
    }

    const clonedRequest = req.clone({
      setHeaders: {
        entitlements: token,
      },
    });

    return next.handle(clonedRequest);
  }
}
