import { DefaultUrlSerializer } from '@angular/router';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { cloneDeep as _cloneDeep, groupBy as _groupBy } from 'lodash';

import { QuickFilterOption } from '@ninety/ui/legacy/components/index';
import { RockLevelCode, RockStatusCode, Team } from '@ninety/ui/legacy/shared/index';
import { DetailType } from '@ninety/ui/legacy/shared/models/_shared/detail-type.enum';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { CurrentUserSelectors, selectAllUsers, TeamSelectors } from '@ninety/ui/legacy/state/index';
import { selectUrl } from '@ninety/ui/legacy/state/route.selectors';
import { WidgetTemplateType } from '@ninety/web/pages/my-ninety/_models/widget-template-type';

import {
  RockModuleStateKey,
  RockModuleStateModel,
  milestonesStateAdapter,
  rocksStateAdapter,
  rocksStateAdapterForCompany,
  rocksStateAdapterForDepartment,
  rocksStateAdapterForUsersNotOnTeam,
} from './rocks-v2-state.model';

export namespace RocksV2StateSelectors {
  export const selectRocksModuleStateModel = createFeatureSelector<RockModuleStateModel>(RockModuleStateKey);
  export const selectLoading = createSelector(selectRocksModuleStateModel, state => state.loading);

  export const selectSelectedTeam = createSelector(TeamSelectors.selectFilterBarTeam, team => team);

  export const selectRocksState = createSelector(
    RocksV2StateSelectors.selectRocksModuleStateModel,
    state => state?.rocks ?? rocksStateAdapter.getInitialState()
  );

  export const { selectAll: selectAllRocks } = rocksStateAdapter.getSelectors();

  export const selectRocks = createSelector(selectRocksState, selectAllRocks);

  export const selectTeamFilterOptions = createSelector(
    selectSelectedTeam,
    CurrentUserSelectors.selectTeams,
    (selectedTeam, teams): QuickFilterOption<Team>[] => {
      if (selectedTeam._id) {
        return teams.map(team => ({
          displayText: team.name,
          id: team._id,
          item: team,
          selected: team._id === selectedTeam._id,
        }));
      }
    }
  );

  export const selectTotalCount = createSelector(selectRocksModuleStateModel, state => state.totalCount);

  export const selectRocksForCompanyState = createSelector(
    selectRocksModuleStateModel,
    state => state?.rocksForCompany ?? rocksStateAdapterForCompany.getInitialState()
  );

  export const selectRocksForDepartmentState = createSelector(
    selectRocksModuleStateModel,
    state => state?.rocksForDepartment ?? rocksStateAdapterForDepartment.getInitialState()
  );

  export const selectRocksForUserNotOnTeamState = createSelector(
    RocksV2StateSelectors.selectRocksModuleStateModel,
    state => state?.rocksForUsersNotOnTeam ?? rocksStateAdapterForUsersNotOnTeam.getInitialState()
  );

  export const { selectAll: selectAllRocksForCompany } = rocksStateAdapterForCompany.getSelectors();
  export const { selectAll: selectAllRocksForDepartment } = rocksStateAdapterForDepartment.getSelectors();
  export const { selectAll: selectAllRocksForUserNotOnTeam } = rocksStateAdapterForUsersNotOnTeam.getSelectors();

  export const selectRocksForCompany = createSelector(selectRocksForCompanyState, selectAllRocksForCompany);
  export const selectRocksForDepartment = createSelector(selectRocksForDepartmentState, selectAllRocksForDepartment);
  export const selectRocksForUserNotOnTeam = createSelector(
    selectRocksForUserNotOnTeamState,
    selectAllRocksForUserNotOnTeam
  );

  export const selectRockForInActiveUsersOnTeam = createSelector(
    selectRocks,
    selectAllUsers,
    selectRocksForUserNotOnTeam,
    selectRocksModuleStateModel,
    (rocks, allUsers, rocksForUsersNotOnTeam, rockState) => {
      //keep previous functionality for feature flag
      if (!rockState.sharedRocksEnabled) {
        return rocksForUsersNotOnTeam;
      }
      const inactiveUserIds = allUsers.filter(user => !user.active).map(user => user._id);
      const nonActiveUserRocks = rocks.filter(rock => inactiveUserIds.includes(rock.userId));

      return nonActiveUserRocks;
    }
  );

  export const selectCompanyRocks = createSelector(selectRocksForCompany, rocks => rocks);
  export const selectDepartmentRocks = createSelector(selectRocksForDepartment, rocks => rocks);

  export const selectCompanyTotalCount = createSelector(selectCompanyRocks, rocks => rocks.length);
  export const selectDepartmentTotalCount = createSelector(selectDepartmentRocks, rocks => rocks.length);
  export const selectUserNotTeamTotalCount = createSelector(selectRockForInActiveUsersOnTeam, rocks => rocks.length);
  export const selectSharedRockNotOnTeamCount = createSelector(selectRocksForUserNotOnTeam, rocks => rocks.length);

  export const selectCompanyRocksCompleted = createSelector(selectCompanyRocks, companyRocksTotal => {
    return (
      companyRocksTotal.filter(
        rock =>
          rock.statusCode === RockStatusCode.complete &&
          (rock.levelCode === RockLevelCode.companyAndDepartment || rock.levelCode === RockLevelCode.company)
      )?.length || 0
    );
  });

  export const selectDepartmentRocksCompleted = createSelector(selectDepartmentRocks, departmentalRocksTotal => {
    return (
      departmentalRocksTotal.filter(
        rock =>
          rock.statusCode === RockStatusCode.complete &&
          (rock.levelCode === RockLevelCode.companyAndDepartment || rock.levelCode === RockLevelCode.department)
      )?.length || 0
    );
  });

  export const selectUserNotOnTeamCompleted = createSelector(selectRocksForUserNotOnTeam, userNotOnTeamRocksTotal => {
    return userNotOnTeamRocksTotal.filter(rock => rock.statusCode === RockStatusCode.complete)?.length || 0;
  });

  export const selectTotalRocks = createSelector(
    selectRocks,
    state => state.filter(completed => completed.statusCode === RockStatusCode.complete).length || 0
  );

  export const selectTotalRocksCompleted = createSelector(
    selectTotalRocks,
    selectUserNotOnTeamCompleted,
    (totalRocks, userNotOnTeamRocks) => {
      return totalRocks + userNotOnTeamRocks;
    }
  );

  export const selectTooltipStatistics = createSelector(
    selectTotalCount,
    selectCompanyRocksCompleted,
    selectCompanyTotalCount,
    selectTotalRocksCompleted,
    selectDepartmentRocksCompleted,
    selectDepartmentTotalCount,
    (
      totalRocksCount,
      companyRocksCompleted,
      companyRocks,
      totalRocksCompleted,
      departmentalRocksCompleted,
      departmentRocksTotal
    ) => {
      const allToolTipData = {
        completionRateAll: getPercentage(totalRocksCount, totalRocksCompleted),
        completionRate: getPercentage(companyRocks, companyRocksCompleted),
        companyRocksDone: `${companyRocksCompleted}/${companyRocks}`,
        allRocksDone: `${totalRocksCompleted}/${totalRocksCount}`,
        departmentCompletionRate: getPercentage(departmentRocksTotal, departmentalRocksCompleted),
        departmentRocksDone: `${departmentalRocksCompleted}/${departmentRocksTotal}`,
      };
      return allToolTipData;
    }
  );
}

export const selectMilestonesState = createSelector(
  RocksV2StateSelectors.selectRocksModuleStateModel,
  state => state?.milestones ?? milestonesStateAdapter.getInitialState()
);

export const { selectAll: selectAllMilestones } = milestonesStateAdapter.getSelectors();

export const selectRocksByUser = (userId: string) =>
  createSelector(RocksV2StateSelectors.selectRocks, rocks => rocks.filter(rock => rock.userId === userId));

export const selectUserRocksCount = (userId: string) =>
  createSelector(RocksV2StateSelectors.selectRocks, rocks => rocks.filter(rock => rock.userId === userId).length);

export const selectMilestonesByUser = (userId: string) =>
  createSelector(selectRocksByUser(userId), rocks => {
    const milestones = rocks.map(rock => rock.milestones).flat();

    return _groupBy(
      milestones.sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()),
      item => item.rockId
    );
  });

export const selectMilestonesForUserNotOnTeamByUser = createSelector(
  RocksV2StateSelectors.selectRocksForUserNotOnTeam,
  rocks => {
    const milestones = rocks.map(rock => rock.milestones).flat();

    return _groupBy(
      milestones.sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()),
      item => item.rockId
    );
  }
);

export const selectMilestonesForCompany = createSelector(RocksV2StateSelectors.selectRocksForCompany, rocks => {
  const milestones = rocks.map(rock => rock.milestones).flat();

  return _groupBy(
    milestones.sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()),
    item => item.rockId
  );
});

export const selectMilestonesForDepartment = createSelector(RocksV2StateSelectors.selectRocksForDepartment, rocks => {
  const milestones = rocks.map(rock => rock.milestones).flat();

  return _groupBy(milestones, item => item.rockId);
});

export const selectMilestones = createSelector(selectMilestonesState, selectAllMilestones);

export const selectFilterBy = createSelector(RocksV2StateSelectors.selectRocksModuleStateModel, state => state.filters);
export const selectFilterByUserId = createSelector(
  RocksV2StateSelectors.selectRocksModuleStateModel,
  state => state.filters.userId
);

export const selectFilterByTeamId = createSelector(
  RocksV2StateSelectors.selectRocksModuleStateModel,
  state => state.filters.teamId
);
// export const selectCompanyId = createSelector(selectCompanyState, state => state.company._id);

export const selectPagination = createSelector(
  RocksV2StateSelectors.selectRocksModuleStateModel,
  state => state.pagination
);
export const selectSort = createSelector(RocksV2StateSelectors.selectRocksModuleStateModel, state => state.sort);
export const selectLoading = createSelector(RocksV2StateSelectors.selectRocksModuleStateModel, state => state.loading);
export const selectRocksV3 = createSelector(RocksV2StateSelectors.selectRocksModuleStateModel, state => state.rocksV3);

export const selectSharedRocksEnabled = createSelector(
  RocksV2StateSelectors.selectRocksModuleStateModel,
  state => state.sharedRocksEnabled
);
export const selectArchivedSortChanged = createSelector(
  RocksV2StateSelectors.selectRocksModuleStateModel,
  state => state.archivedSortChanged
);

export const selectIsFilteredList = createSelector(
  RocksV2StateSelectors.selectRocksModuleStateModel,
  state => state.isFilteredList
);

export const selectShowArchived = createSelector(
  RocksV2StateSelectors.selectRocksModuleStateModel,
  state => state.showArchived
);

export const selectError = createSelector(RocksV2StateSelectors.selectRocksModuleStateModel, state => state.error);

export const selectFilterByRockStatus = createSelector(RocksV2StateSelectors.selectRocksModuleStateModel, state =>
  (state.filters.statusCode as string) === 'all' ? null : state.filters.statusCode
);
export const selectGetRockQueryParams = createSelector(
  selectFilterBy,
  selectPagination,
  selectSort,
  (filterBy, pagination, sort) => ({
    pageIndex: pagination.index,
    pageSize: pagination.size,
    ...(sort.direction != null ? { sortField: sort.field, sortDirection: sort.direction } : null),
    ...((filterBy.statusCode as string) === 'all' ? null : { statusCode: filterBy.statusCode }),
    ...(filterBy.teamId ? { teamId: filterBy.teamId } : null),
    ...(filterBy.userId ? { userId: filterBy.userId } : null),
  })
);

//TODO NEXT: we might need a different version for the regular rocks page
export const selectTemplateType = createSelector(
  RocksV2StateSelectors.selectLoading,
  selectError,
  RocksV2StateSelectors.selectTotalCount,
  selectFilterBy,
  (loading, error, totalCount, filterBy) => {
    if (loading) {
      return WidgetTemplateType.loading;
    }
    if (error) {
      return WidgetTemplateType.error;
    }
    if ((filterBy.teamId || filterBy.statusCode) && totalCount === 0) {
      return WidgetTemplateType.noQueryResults;
    }
    if (totalCount === 0) {
      return WidgetTemplateType.empty;
    }
    return WidgetTemplateType.data;
  }
);

export const selectSelectedRockId = createSelector(
  RocksV2StateSelectors.selectRocksModuleStateModel,
  state => state?.selectedRockId
);
export const selectSelectedMilestoneId = createSelector(
  RocksV2StateSelectors.selectRocksModuleStateModel,
  state => state?.selectedMilestoneId ?? null
);

export const selectMilestonesByRock = createSelector(selectMilestones, milestones =>
  _groupBy(milestones, item => item.rockId)
);

export const selectUserMilestonesByRock = createSelector(selectMilestones, selectFilterByUserId, (milestones, userId) =>
  _groupBy(
    milestones.filter(m => m.ownedByUserId === userId),
    item => item.rockId
  )
);

// To - Do: Wrap all selectors in a const or namespace
export const RockSelectors = {
  selectRockById: (id: string) =>
    createSelector(RocksV2StateSelectors.selectRocksState, rocksState => rocksState.entities[id]),
};
export const selectSelectedRock = createSelector(
  RocksV2StateSelectors.selectRocksState,
  selectSelectedRockId,
  RocksV2StateSelectors.selectRocksForUserNotOnTeamState,
  (rocksState, selectedRockId, rocksForUserNotOnTeamState) =>
    rocksState.entities[selectedRockId] || rocksForUserNotOnTeamState.entities[selectedRockId]
);

export const selectMilestoneById = (id: string) =>
  createSelector(selectMilestonesState, milestonesState => milestonesState.entities[id]);
export const selectSelectedMilestone = createSelector(
  selectMilestonesState,
  selectSelectedMilestoneId,
  (milestonesState, selectedMilestoneId) => milestonesState.entities[selectedMilestoneId]
);

export const selectRockHasMilestoneForUser = (rockId: string, userId: string) =>
  createSelector(selectMilestonesForRock(rockId), milestones =>
    milestones.some(milestone => milestone.ownedByUserId === userId)
  );

export const selectMilestonesForRock = (id: string) =>
  createSelector(selectMilestones, milestones => milestones.filter(m => m.rockId === id));

export const selectRockDetailViewData = (id: string) =>
  createSelector(RockSelectors.selectRockById(id), selectMilestonesForRock(id), (rock, milestones) => {
    const rockToOpen = {
      type: DetailType.rock,
      input: {
        item: _cloneDeep({ ...rock, milestones }), //_cloneDeep needed so we can update from detail view
        itemType: ItemType.rock,
        itemUrl: `${location.origin}/rocks/${rock._id}`,
      },
    };

    return rockToOpen;
  });

export const selectRockV2DetailViewData = (detailType: DetailType, id: string) =>
  createSelector(RockSelectors.selectRockById(id), selectMilestonesForRock(id), (rock, milestones) => {
    const rockToOpen = {
      type: detailType,
      input: {
        item: _cloneDeep({ ...rock, milestones }), //_cloneDeep needed so we can update from detail view
        itemType: ItemType.rock,
        itemUrl: `${location.origin}/rocks/${rock._id}`,
      },
    };

    return rockToOpen;
  });

export const selectRockV3DetailViewData = (detailtype: DetailType, id: string) =>
  createSelector(RockSelectors.selectRockById(id), selectMilestonesForRock(id), (rock, milestones) => {
    const rockToOpen = {
      type: detailtype,
      input: {
        item: _cloneDeep({ ...rock, milestones }), //_cloneDeep needed so we can update from detail view
        itemType: ItemType.rock,
        itemUrl: `${location.origin}/rock-v3/${rock._id}`,
      },
    };

    return rockToOpen;
  });

export const selectMilestoneDetailViewData = (type: DetailType) =>
  createSelector(selectSelectedMilestoneId, milestoneId => {
    const milestoneToOpen = {
      type: type,
      input: {
        itemType: ItemType.milestone,
        itemUrl: `${location.origin}/milestones/${milestoneId}`,
      },
    };
    return milestoneToOpen;
  });

export const selectRockIdFromDetailRoute = createSelector(selectUrl, url => {
  const parsedUrl = new DefaultUrlSerializer().parse(url);
  if (
    parsedUrl?.root?.children?.detail?.segments[1]?.path === DetailType.rockStoreV2 &&
    parsedUrl.root.children.detail.segments[2]?.path
  )
    return parsedUrl.root.children.detail.segments[2].path;
  if (
    parsedUrl?.root?.children?.detail?.segments[1]?.path === DetailType.rockV3 &&
    parsedUrl.root.children.detail.segments[2]?.path
  )
    return parsedUrl.root.children.detail.segments[2].path;
  return null;
});

export const selectRockIdFromRoute = createSelector(selectUrl, url => {
  const parsedUrl = new DefaultUrlSerializer().parse(url);
  if (
    parsedUrl.root.children?.primary?.segments[0].path === 'rocks-v3' ||
    parsedUrl.root.children?.detail?.segments[1].path === 'rock-v3'
  ) {
    return parsedUrl.root.children.detail
      ? parsedUrl.root.children.detail.segments[2]?.path
      : parsedUrl.root.children.primary.segments[1]?.path;
  }
  return null;
});

export const selectMilestoneIdFromRoute = createSelector(selectUrl, url => {
  const parsedUrl = new DefaultUrlSerializer().parse(url);
  if (
    parsedUrl.root.children?.primary?.segments[0].path === 'rocks-v3' ||
    parsedUrl.root.children?.detail?.segments[1].path === 'milestone-v2'
  ) {
    if (parsedUrl.root.children?.detail?.segments[1].path === 'milestone-v2')
      return parsedUrl.root.children.detail.segments[2]?.path;
  } else if (parsedUrl.root.children?.primary?.segments[0].path === 'milestones')
    return parsedUrl.root.children.primary.segments[1]?.path;
  return null;
});

function getPercentage(setSize: number, numComplete: number): string {
  if (!numComplete) return '0';
  if (setSize === numComplete) return '100';
  return ((numComplete / setSize) * 100).toFixed(1);
}
