import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { BidiModule, Directionality } from '@angular/cdk/bidi';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DOWN_ARROW, END, ENTER, ESCAPE, HOME, SPACE, TAB, UP_ARROW } from '@angular/cdk/keycodes';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Self,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { BehaviorSubject, delay, filter, map, merge, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { TerraInputBoolean } from '../../models';
import { TerraErrorStateMatcher } from '../forms/terra-error-state-matcher';
import { TerraDividerComponent } from '../terra-divider';
import { TerraIconModule } from '../terra-icon';
import { TerraOptionBase, TERRA_OPTION_BASE } from '../terra-option/terra-option.interface';

import { TerraSelectBaseClass } from './terra-select-base/terra-select-base';
import { terraSelectAnimations } from './terra-select.animations';

export type TerraSelectLayout = 'list' | 'icons';

let selectUniqueId = 1;

@Component({
  selector: 'terra-select',
  standalone: true,
  exportAs: 'terraSelect',
  imports: [CommonModule, TerraIconModule, BidiModule],
  templateUrl: './terra-select.component.html',
  styleUrls: ['./terra-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.terra-select__icons-layout]': 'layout === "icons"',
    '(keydown)': '_manageKeyEvents($event)',
  },
  animations: [terraSelectAnimations.transformPanel],
})
export class TerraSelectComponent
  extends TerraSelectBaseClass
  implements ControlValueAccessor, AfterContentInit, OnDestroy, OnInit
{
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  private _onChange!: ((_: any) => void) | undefined;
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  private _onTouched!: (_: any) => void;

  @ContentChildren(TERRA_OPTION_BASE, { descendants: true }) private _options!: QueryList<TerraOptionBase>;
  /**
   * @ignore
   * Implemented to support the TQF (Terra Quick Filter) component.
   * Options content-projected from the TQF use ngProjectAs and aren't considered Content Children.
   * _backdoorOptions allows TQF to pass the options manually to the select.
   * */
  // eslint-disable-next-line rxjs/no-exposed-subjects
  _backdoorOptions = new BehaviorSubject<QueryList<TerraOptionBase> | undefined>(undefined);

  @ContentChildren(TerraDividerComponent) private _dividers!: QueryList<TerraDividerComponent>;

  @ViewChild('selectPanel', { read: TemplateRef, static: true }) private _selectPanel!: TemplateRef<HTMLElement>;

  @ViewChild('trigger', { static: true }) private _trigger!: ElementRef<HTMLElement>;

  protected _selectId = `terra-select-${selectUniqueId++}`;

  private _destroyed$ = new Subject<void>();

  private _overlayRef?: OverlayRef;

  private _keyManager!: ActiveDescendantKeyManager<TerraOptionBase>;

  private _isFocused = false;

  private _componentHasInitialized = false;
  /**
   * Whether the NgModel has been initialized.
   *
   * This flag is used to ignore ghost null calls to
   * writeValue which can break slider initialization.
   *
   * See https://github.com/angular/angular/issues/14988.
   * Following Material's solution for this:
   * https://github.com/angular/components/pull/27149/files
   */
  protected _isFormControlInitialized = false;

  private get _getOptions(): QueryList<TerraOptionBase> {
    if (this._backdoorOptions.value) {
      return this._backdoorOptions.value;
    }
    return this._options;
  }

  protected get _activeDescendant(): string | undefined {
    return this.isSelectOpen ? this._keyManager?.activeItem?.optionId : undefined;
  }

  // Current state of the panel animation
  protected _panelAnimationState: 'void' | 'enter' = 'void';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get value(): any {
    return this._valueBS.getValue();
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _valueBS = new BehaviorSubject<any>(undefined);
  private readonly _value$ = this._valueBS.asObservable();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected _prefixTemplate?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected _suffixTemplate?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected _iconTemplate?: any;
  protected _label?: string;

  /**
   * Allow multiple selections, can't be changed after initialization
   * @default false
   */
  @Input({ required: false }) get multiple(): boolean {
    return this._multiple;
  }
  set multiple(value: TerraInputBoolean) {
    // We don't support this input chaning after initialization
    if (this._componentHasInitialized === false) {
      // If layout is icons then we just silently don't support this property
      if (this._layout === 'list') {
        this._multiple = coerceBooleanProperty(value);
        this._changeDetectorRef.markForCheck();
      }
    } else {
      // Switching from one mode to the other would add a lot of complexity
      // consumers can have many selects with different modes if needed
      throw new Error('Multiple mode cannot be changed after it has been set');
    }
  }
  private _multiple = false;

  /**
   * Disabled state of the select
   * @default false
   */
  @Input() get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: TerraInputBoolean) {
    this._disabled = coerceBooleanProperty(value);
    this._getOptions?.forEach(option => (option.disabledOverride = this._disabled));

    if (this._disabled && this.isSelectOpen) {
      // We close if it's become disabled as it wouldn't be accessible if it was disabled when not already open
      this.close();
    }
    this._changeDetectorRef.markForCheck();
  }
  private _disabled = false;

  /**
   * Layout of the select, can't be changed after initialization
   * @default 'list'
   */
  @Input() get layout(): TerraSelectLayout {
    return this._layout;
  }
  set layout(value: TerraSelectLayout) {
    // We don't support this input changing after initialization
    if (this._componentHasInitialized === false) {
      this._layout = value;
      this._changeDetectorRef.markForCheck();
    } else {
      throw new Error('Layout cannot be changed after it has been set');
    }
  }
  private _layout: TerraSelectLayout = 'list';

  constructor(
    protected override _changeDetectorRef: ChangeDetectorRef,
    protected override _terraErrorStateMatcher: TerraErrorStateMatcher,
    private readonly _overlay: Overlay,
    private readonly _elementRef: ElementRef,
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _direction: Directionality,
    @Self() protected readonly ngControl: NgControl
  ) {
    super(_changeDetectorRef, _terraErrorStateMatcher);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this._componentHasInitialized = true;
  }

  ngAfterContentInit(): void {
    const mergedOptions$ = merge(
      this._options.changes.pipe(startWith(this._options)),
      this._backdoorOptions.asObservable().pipe(filter(value => !!value))
    );

    const optionsSetInputs$ = mergedOptions$.pipe(
      tap(() => {
        // Sets the disabled state of the options when they are first initialized
        this._getOptions.forEach(option => {
          option.disabledOverride = this._disabled;
        });

        // If in multiple mode we need to set the checkbox for initial options and future options
        if (this.multiple) {
          // Timeout to avoid changeAfterChecked error
          setTimeout(() => {
            this._getOptions.forEach(option => {
              option.checkbox = true;
            });
          });
        }
      })
    );

    // Any option is selected
    const optionSelected$ = mergedOptions$.pipe(
      switchMap((options: QueryList<TerraOptionBase>) => {
        return merge(...options.map(option => option.select));
      })
    );

    // Option of a different value is selected, then emit the new value
    const newOptionSelected$ = optionSelected$.pipe(
      // Update the value stream with corrected values
      filter(event => {
        // If the value is the same as the current value we don't emit in single mode
        return this._multiple || (!this._multiple && !this._compareWith(event, this.value));
      }),
      tap(event => {
        if (this._multiple) {
          if (event === undefined) {
            // An empty string value of the option is selected, so we emit an empty array
            this._valueBS.next([]);
          } else if (this.value === undefined) {
            // The value of the option was undefined, so we emit an array with the value
            this._valueBS.next([event]);
          } else if (this._multipleValueContainsOptionValue(event)) {
            // The value of the option was already selected, so we remove it (toggle)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this._valueBS.next(this.value.filter((v: any) => v !== event));
          } else {
            // The value of the option hasn't been selected so we add it to the array
            this._valueBS.next([...this.value, event]);
          }
        } else {
          this._valueBS.next(event);
        }
      }),
      // When an option has been selected we mark the form as touched and update the form value
      // These don't need to happen when the form value changes from the parent
      tap(() => {
        this._touch();
        this._onChange?.(this.value);
        this.selectionChange.emit(this.value);
      })
    );

    // When the content of any of the options changes (like actual template changes)
    // Ignore if the value isn't currently selected
    const optionContentChanged$ = mergedOptions$.pipe(
      switchMap((options: QueryList<TerraOptionBase>) => {
        return merge(...options.map(option => option.content));
      }),
      filter(() => {
        // Ignore event stream if value is currently undefined
        return this.value !== undefined;
      }),
      filter(value => {
        // Is the option that changed the currently selected option
        return this._multiple ? this._multipleValueContainsOptionValue(value) : this._compareWith(value, this.value);
      }),
      tap(_option => {
        if (this._multiple) {
          this._label = this._getOptions
            .filter(option => option.selected)
            .map(option => option.getLabel())
            .join(', ');
        } else {
          const selectedOption = this._getOptions.find(option => option.selected);
          if (selectedOption) {
            this._updateTemplates(selectedOption);
          }
        }
      }),
      tap(() => {
        this._changeDetectorRef.markForCheck();
      })
    );

    // Stream of when the options list changes
    const optionChanges$ = mergedOptions$.pipe(
      map(() => {
        // Return the current value, so that we can compare it against all new options
        return this.value;
      }),
      // Delay needed to give new options time to settle before _selected value is changed
      delay(0)
    );

    // When the value stream changes or the options list changes
    const valueChanged$ = merge(this._value$, optionChanges$).pipe(
      // Update the ui
      // Updates the state of each option
      // Updates the trigger portion of the select
      tap(event => {
        if (this._multiple && event === undefined) {
          // If the value is undefined we clear all options
          this._getOptions.forEach(option => {
            option.selected = false;
          });
          this._label = '';
        } else if (this._multiple) {
          const labels: string[] = [];
          // For multiple we need to update the state of each option and get the selected labels for the trigger
          this._getOptions.forEach(option => {
            if (this._multipleValueContainsOptionValue(option.value)) {
              option.selected = true;
              labels.push(option.getLabel());
            } else {
              option.selected = false;
            }
            this._label = labels.join(', ');
          });
        } else if (!this._multiple) {
          // Clear templates
          this._prefixTemplate = this._suffixTemplate = this._label = this._iconTemplate = undefined;
          this._getOptions.forEach(option => {
            if (event === undefined) {
              // If an option is "undefined" then this will short circuit and the placeholder will show
              // which allows an option to reset the select to initial state (unlike HTML select)
              option.selected = false;
            } else if (this._compareWith(option.value, event)) {
              option.selected = true;
              this._updateTemplates(option);
            } else {
              option.selected = false;
            }
          });
        }
      }),
      tap(() => {
        this._changeDetectorRef.markForCheck();
      })
    );

    // Stream of actions that should close the select
    const closeActions$ = this.openedChange.pipe(
      tap(selectState => {
        // Update the state of the selectOpen
        this._isSelectOpen = selectState;
      }),
      filter(() => this.isSelectOpen && !!this._overlayRef),
      switchMap(() => {
        // Clicks on the "invisible" backdrop that covers the rest of the page
        const backdrop$ = this._overlayRef?.backdropClick();
        // Detachments are when the overlay is removed from the DOM
        // Not entirely sure when this would happen, but here because Material uses this pattern
        const detachments$ = this._overlayRef?.detachments();
        const actions = [backdrop$, detachments$];

        // We only want to close the select when an option is selected when NOT in multiple mode
        if (!this._multiple) {
          actions.push(optionSelected$);
        }
        return merge(...actions);
      }),
      tap(() => {
        this.close();
      })
    );

    this._keyManager = new ActiveDescendantKeyManager(this._getOptions)
      .withPageUpDown()
      .withHomeAndEnd()
      .withVerticalOrientation()
      .withTypeAhead()
      .withWrap();

    const dividersEnforcer$ = this._dividers.changes.pipe(
      startWith(this._dividers),
      tap((dividers: QueryList<TerraDividerComponent>) => {
        dividers.forEach(divider => {
          divider.height = 'short';
          divider.margins = 'narrow';
        });
      })
    );

    // Because of issues with ngModel we have to wait one extra turn
    // With ReactiveForms the valueBS is already set to the correct value before we subscribe
    // ngModel is not quite settled yet, but will be after an additional turn, it's annoying
    setTimeout(() => {
      const streams = [optionsSetInputs$, newOptionSelected$, valueChanged$, closeActions$, dividersEnforcer$];

      // The icon options don't support content changing so we only need this in list mode
      if (this._layout === 'list') {
        streams.push(optionContentChanged$);
      }

      merge(...streams)
        .pipe(takeUntil(this._destroyed$))
        .subscribe();
    }, 0);
  }

  ngOnDestroy(): void {
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._overlayRef = undefined;
    }
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /**
   * Focuses the select
   */
  focus(): void {
    this._trigger.nativeElement.focus();

    // Manually call event so that (focus) on template correctly reacts
    this._trigger.nativeElement.dispatchEvent(new FocusEvent('focus'));
  }

  /**
   * Blurs the select
   */
  blur(): void {
    this._trigger.nativeElement.blur();

    // Manually call event so that (blur) on template correctly reacts
    this._trigger.nativeElement.dispatchEvent(new FocusEvent('blur'));
  }

  /** Toggle the current state open/close */
  toggle(): void {
    this.isSelectOpen ? this.close() : this.open();
  }

  /** Open the select if able */
  open(): void {
    if (this._canOpen()) {
      this._openSelectPanel();
      if (typeof this.value !== 'undefined') {
        const selectedIndex = this._getOptions
          .toArray()
          .findIndex(option =>
            this.multiple
              ? this._multipleValueContainsOptionValue(option.value)
              : this._compareWith(option.value, this.value)
          );
        this._keyManager.setActiveItem(selectedIndex > -1 ? selectedIndex : 0);

        // Timeout is needed because it wasn't scrolling properly to item at top of long list without it
        setTimeout(() => {
          this._keyManager.activeItem?.setActiveStyles();
        });
      } else {
        this._keyManager.setFirstItemActive();
      }
      this._enterAnimation();
      this.openedChange.emit(true);

      // Manually have to focus the input for search input
      // The Quick Filter version couldn't query for the selectInput with Angular
      this._overlayRef?.hostElement?.querySelector('input')?.focus();

      this._changeDetectorRef.markForCheck();
    }
  }

  /** Close the select if open */
  close(): void {
    if (this.isSelectOpen) {
      this.openedChange.emit(false);
      this._overlayRef?.detach();
      this._exitAnimation();

      // If the select is closed we consider the form 'touched'
      // This is slight deviation from Material, but it makes sense to us
      // versus only marking touched once a value is selected, which makes more sense as dirty
      this._touch();

      this._changeDetectorRef.markForCheck();
    }
  }

  /** Scrolls the options list to the top */
  scrollToTop(): void {
    this._keyManager.setFirstItemActive();
  }

  protected _focused(event: FocusEvent): void {
    this._isFocused = true;
    // Blur/focus events don't bubble, so we have to dispatch a custom event to one above us
    const customEvent = new CustomEvent('focus', {
      bubbles: false,
      cancelable: true,
      detail: { originalEvent: event },
    });
    this._elementRef.nativeElement.dispatchEvent(customEvent);
  }

  protected _blurred(event: FocusEvent): void {
    this._isFocused = false;
    // Blur/focus events don't bubble, so we have to dispatch a custom event to one above us
    const customEvent = new CustomEvent('blur', { bubbles: false, cancelable: true, detail: { originalEvent: event } });
    this._elementRef.nativeElement.dispatchEvent(customEvent);
  }

  private _canOpen(): boolean {
    return !this.disabled && !this.isSelectOpen;
  }

  private _updateTemplates(option: TerraOptionBase): void {
    if (option._iconTemplate) {
      this._iconTemplate = option._iconTemplate;
    } else {
      this._prefixTemplate = option._prefixTemplate;
      this._suffixTemplate = option._suffixTemplate;
      this._label = option.getLabel();
    }
  }

  // Implemented as part of ControlValueAccessor.

  /**
   * @ignore
   * Implemented as part of ControlValueAccessor. */
  // Value has changed from parent
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(value: any): void {
    if (this._isFormControlInitialized || value !== null) {
      // We don't allow value to be set not to an array when in multiple mode, except for undefined
      if (this._multiple && !Array.isArray(value) && value !== undefined) {
        throw new Error('Value must be an array in multiple mode');
      }
      this._valueBS.next(value);
    }
  }

  /**
   * @ignore
   * Implemented as part of ControlValueAccessor. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this._onChange = fn;
    this._isFormControlInitialized = true;
  }

  /**
   * @ignore
   * Implemented as part of ControlValueAccessor. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  /**
   * @ignore
   * Implemented as part of ControlValueAccessor. */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._changeDetectorRef.markForCheck();
  }

  protected _touch(): void {
    this._onTouched(true);
  }

  // Overlay

  private _openSelectPanel(): void {
    const overlayRef = this._createOverlay(this._elementRef);

    const portal = new TemplatePortal(this._selectPanel, this._viewContainerRef);

    overlayRef.attach(portal);
  }

  private _createOverlay(connectedTo: ElementRef): OverlayRef {
    if (!this._overlayRef) {
      const overlayConfig = new OverlayConfig({
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-transparent-backdrop',
        scrollStrategy: this._overlay.scrollStrategies.block(),
        direction: this._direction,
        minWidth: this._elementRef.nativeElement.getBoundingClientRect().width,
        maxWidth: this.layout === 'icons' ? this._maxWidth ?? 172 : this._maxWidth ?? 'calc(100% - 16px)',
        width: this.layout === 'icons' ? '100%' : 'unset',
        maxHeight: this._maxHeight,
        positionStrategy: this._overlay
          .position()
          .flexibleConnectedTo(connectedTo)
          .withPositions([
            {
              // Left aligned below
              originX: 'start',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'top',
              offsetY: 4,
            },
            {
              // Right aligned below
              originX: 'end',
              originY: 'bottom',
              overlayX: 'end',
              overlayY: 'top',
              offsetY: 4,
            },
            {
              // Left aligned above
              originX: 'start',
              originY: 'top',
              overlayX: 'start',
              overlayY: 'bottom',
              offsetY: -4,
            },
            {
              // Right aligned above
              originX: 'end',
              originY: 'top',
              overlayX: 'end',
              overlayY: 'bottom',
              offsetY: -4,
            },
          ])
          .withFlexibleDimensions(true)
          .withGrowAfterOpen(true)
          .withLockedPosition(true)
          .withViewportMargin(8),
      });
      this._overlayRef = this._overlay.create(overlayConfig);
    }
    return this._overlayRef;
  }

  // Keybindings for a11y, some aren't correct or included with KeyManager
  // Following guidelines from ARIA:  https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-select-only/
  protected _manageKeyEvents(event: KeyboardEvent) {
    const keyCode = event.keyCode;
    if (this.isSelectOpen) {
      switch (keyCode) {
        case ESCAPE:
          event.stopPropagation();
          this.close();
          break;
        case SPACE:
        case ENTER:
        case TAB:
          event.preventDefault();
          this._keyManager.activeItem?._onClick(event);
          this.focus();
          break;
        default:
          this._keyManager.onKeydown(event);
      }
    } else if (this._isFocused) {
      switch (keyCode) {
        case SPACE:
        case ENTER:
        case DOWN_ARROW:
          this.open();
          event.preventDefault();
          break;
        case END:
          this.open();
          event.preventDefault();
          this._keyManager.setLastItemActive();
          break;
        case HOME:
        case UP_ARROW:
          this.open();
          this._keyManager.setFirstItemActive();
          event.preventDefault();
          break;
      }
    }
  }

  protected _searchInputKeypressHandler(event: KeyboardEvent): void {
    switch (event.keyCode) {
      case UP_ARROW:
      case DOWN_ARROW:
        this._keyManager.onKeydown(event);
        break;
      case ENTER:
        event.preventDefault();
        this._keyManager.activeItem?._onClick(event);
        this.focus();
        break;
      case ESCAPE:
        // By default HTML input type="search" will clear the results when escape is pressed
        // But because we attempt to change focus the browser doesn't finish clearing the formControl
        // setTimeout guarantees it clears correctly before we move focus
        event.stopPropagation();
        setTimeout(() => {
          this.focus();
        }, 0);
        break;
      default:
        event.stopPropagation();
        break;
    }
  }

  // Animation
  // Starts enter animation
  private _enterAnimation(): void {
    this._panelAnimationState = 'enter';
  }

  // Starts exit animation
  private _exitAnimation(): void {
    this._panelAnimationState = 'void';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _multipleValueContainsOptionValue(optionValue: any): boolean {
    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.value.findIndex((val: any) => {
        return this._compareWith(optionValue, val);
      }) > -1
    );
  }
}
