import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Observable, Subject, Subscription, catchError, tap } from 'rxjs';

import { ChannelService } from '@ninety/ui/legacy/core/services/channel.service';
import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { NotifyService } from '@ninety/ui/legacy/core/services/notify.service';
import { QueryParamsService } from '@ninety/ui/legacy/core/services/query-params.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { OrdinalOrUserOrdinalUpdate } from '@ninety/ui/legacy/shared/models/_shared/ordinal-or-user-ordinal-update';
import { OrdinalUpdate } from '@ninety/ui/legacy/shared/models/_shared/ordinal-update';
import { CascadingMessagesSortField } from '@ninety/ui/legacy/shared/models/cascading-messages/cascading-message-sort';
import { GetCascadingMessagesQueryParams } from '@ninety/ui/legacy/shared/models/cascading-messages/get-cascading-messages-query-params';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { SortDirection } from '@ninety/ui/legacy/shared/models/enums/sort-direction';
import { ExcelHeadlinesAndCMsSortOptions } from '@ninety/ui/legacy/shared/models/headlines/excel-headlines-and-cms';
import { GetHeadlinesQueryParams } from '@ninety/ui/legacy/shared/models/headlines/get-headlines-query-params';
import { HeadlinesSortField } from '@ninety/ui/legacy/shared/models/headlines/headline-sort';
import { FromLinkedItem } from '@ninety/ui/legacy/shared/models/linked-items/linked-item-type-enum';
import { CascadingMessagesResponse } from '@ninety/ui/legacy/shared/models/meetings/cascading-messages-response';
import { Headline } from '@ninety/ui/legacy/shared/models/meetings/headline';
import { HeadlineMessageType } from '@ninety/ui/legacy/shared/models/meetings/headline-message-type';
import { HeadlineOrdinalType } from '@ninety/ui/legacy/shared/models/meetings/headline-ordinal-type';
import { HeadlinesResponse } from '@ninety/ui/legacy/shared/models/meetings/headlines-response';
import { ListSortMessage } from '@ninety/ui/legacy/shared/models/meetings/list-sort-message';
import type {
  RealtimeMessage,
  ReceivedRealtimeMessage,
} from '@ninety/ui/legacy/shared/models/meetings/realtime-message';

import { CascadingMessagesStateActions } from '../../_state/cascading-messages/cascading-messages-state.actions';
import { HeadlinesStateActions } from '../../_state/headlines/headlines-state.actions';
import { HeadlinesFilterBy } from '../../_state/headlines/headlines-state.model';

@Injectable({
  providedIn: 'root',
})
export class HeadlineService {
  private headlinesApi = '/api/v4/Headlines';

  private updateLocalHeadlineBS$ = new Subject<{ update: Partial<Headline>; isCascadingMessage: boolean }>();
  public updateLocalHeadline$ = this.updateLocalHeadlineBS$.asObservable();

  private deleteLocalHeadlineBS$ = new Subject<{ id: string; isCascadingMessage: boolean }>();
  public deleteLocalHeadline$ = this.deleteLocalHeadlineBS$.asObservable();

  private newHeadlinesFromUniversalCreateBS$ = new Subject<{ headlines: Headline[] }>();
  public newHeadlinesFromUniversalCreate$ = this.newHeadlinesFromUniversalCreateBS$.asObservable();

  private newCascadingMessageFromUniversalCreateBS$ = new Subject<{ headlines: Headline[] }>();
  public newCascadingMessageFromUniversalCreate$ = this.newCascadingMessageFromUniversalCreateBS$.asObservable();

  private archiveLocalHeadlineBS$ = new Subject<{ headline: Headline }>();
  public archiveLocalHeadline$ = this.archiveLocalHeadlineBS$.asObservable();

  private addNewHeadlineLocalBS$ = new Subject<{ headline: Headline }>();
  public addNewHeadlineLocal$ = this.addNewHeadlineLocalBS$.asObservable();

  private sortLocalBS$ = new Subject<{ sortMessage: ListSortMessage }>();
  public sortLocal$ = this.sortLocalBS$.asObservable();

  channelId: string;
  shouldBroadcast = false;
  messageSubscription = new Subscription();

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private stateService: StateService,
    private notifyService: NotifyService,
    private channelService: ChannelService,
    private store: Store
  ) {}

  broadcastMessage(message: RealtimeMessage) {
    if (this.shouldBroadcast) {
      this.channelService.sendMessage(this.channelId, this.sanitizeMessage(message)).subscribe();
    }
  }

  subscribeToHeadlineChannel(teamId: string) {
    this.channelId = `headline-${this.stateService.companyId}-${teamId}`;
    this.shouldBroadcast = true;
    this.subscribeToMessages();
  }

  subscribeToMessages() {
    this.messageSubscription = this.channelService.messageReceived$.subscribe({
      next: message => {
        switch (message.messageType) {
          case HeadlineMessageType.headline:
          case HeadlineMessageType.new:
          case HeadlineMessageType.delete:
          case HeadlineMessageType.archive:
            this.executeHeadlineMessage(message);
            break;
          case HeadlineMessageType.sort:
            //filter messages that might have sortDirection, old headlines use order
            if (message.document.hasOwnProperty('sortDirection')) {
              this.sortLocalBS$.next({ sortMessage: message.document });
            }
            break;
          case HeadlineMessageType.fetch:
            this.handleMessageWithApiGet(message);
            break;
        }
      },
      error: (err: unknown) => this.errorService.oops(err),
    });
  }

  destroyHeadlineChannel() {
    this.messageSubscription.unsubscribe();
  }

  executeHeadlineMessage(message: ReceivedRealtimeMessage) {
    switch (message.messageType) {
      case HeadlineMessageType.headline:
        this.updateLocalHeadlineBS$.next({ update: message.document, isCascadingMessage: message.isCascadingMessage });
        break;
      case HeadlineMessageType.new:
        this.addNewHeadlineLocalBS$.next({ headline: message.document });
        break;
      case HeadlineMessageType.archive:
        this.archiveLocalHeadlineBS$.next({ headline: message.document });
        break;
      case HeadlineMessageType.delete:
        this.deleteLocalHeadlineBS$.next({ id: message.document, isCascadingMessage: message.isCascadingMessage });
        break;
      default:
        // console.error('Invalid Headline Message Type:' + message.messageType);
        break;
    }
  }

  getHeadlineById(id: string): Observable<Headline> {
    return this.http
      .get<Headline>(`${this.headlinesApi}/${id}`)
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(e, `Could not get ${this.stateService.language.headline.item}.  Please try again.`)
        )
      );
  }

  getHeadlinesByTeamId(params: GetHeadlinesQueryParams): Observable<HeadlinesResponse> {
    //TODO NEXT: request still returns ordinals along with data,
    //new version doesn't need that but we still use it on the meetings, remove asap
    return this.http
      .get<HeadlinesResponse>(`${this.headlinesApi}`, {
        params: QueryParamsService.build({ ...params, isCascadedMessage: false }, true),
      })
      .pipe(catchError((e: unknown) => this.errorService.notify(e, `Could not get Headlines. Please try again.`)));
  }

  getCascadingMessagesByTeamId(params: GetCascadingMessagesQueryParams): Observable<CascadingMessagesResponse> {
    //TODO NEXT: request still returns ordinals along with data,
    //new version doesn't need that but we still use it on the meetings, remove asap
    return this.http
      .get<CascadingMessagesResponse>(`${this.headlinesApi}`, {
        params: QueryParamsService.build({ ...params, isCascadedMessage: true }, true),
      })
      .pipe(
        catchError((e: unknown) => this.errorService.notify(e, `Could not get Cascaded Messages. Please try again.`))
      );
  }

  getHeadlinesByMeetingId(meetingId: string): Observable<HeadlinesResponse> {
    const params = QueryParamsService.build({
      isCascadedMessage: false,
    });
    return this.http
      .get<HeadlinesResponse>(`${this.headlinesApi}/Meeting/${meetingId}`, { params })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(e, `Could not get ${this.stateService.language.headline.items}. Please try again.`)
        )
      );
  }

  getCascadingMessagesByMeetingId(meetingId: string): Observable<CascadingMessagesResponse> {
    const params = QueryParamsService.build({
      isCascadedMessage: true,
    });
    return this.http
      .get<CascadingMessagesResponse>(`${this.headlinesApi}/Meeting/${meetingId}`, { params })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not get ${this.stateService.language.cascadingMessage.items}. Please try again.`
          )
        )
      );
  }

  updateHeadline(headlineId: string, update: Partial<Headline>): Observable<Headline> {
    return this.http
      .patch<Headline>(`${this.headlinesApi}/${headlineId}`, update)
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not update ${this.stateService.language.headline.item}.  Please try again.`
          )
        )
      );
  }

  updateOrdinals(
    models: OrdinalOrUserOrdinalUpdate[],
    ordinalKey: HeadlineOrdinalType = 'ordinal', // based on todos - prepared for the store in the future
    teamId: string,
    cascaded: boolean,
    inMeetingId: string,
    sortField?: HeadlinesSortField | CascadingMessagesSortField,
    sortDirection?: SortDirection
  ) {
    return this.http
      .put<OrdinalUpdate[]>(`${this.headlinesApi}/Ordinals`, {
        sort: { field: sortField, direction: sortDirection },
        teamId,
        ...(inMeetingId ? { inMeetingId } : {}),
        ordinalKey,
        isCascadedMessage: cascaded,
        models,
      })
      .pipe(catchError((e: unknown) => this.errorService.notify(e, `Could not update the order.  Please try again.`)));
  }

  createHeadline(headline: Headline, createdFrom?: FromLinkedItem): Observable<Headline[]> {
    return this.http
      .post<Headline[]>(this.headlinesApi, { models: [headline], from: createdFrom })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not create ${this.stateService.language.headline.item}.  Please try again.`
          )
        )
      );
  }

  createHeadlinesFromUniversalCreate(
    headline: Headline,
    itemType: ItemType,
    createdFrom: FromLinkedItem
  ): Observable<Headline[]> {
    return this.createHeadline(headline, createdFrom).pipe(
      tap(headlines => {
        if (itemType === ItemType.headline) {
          this.newHeadlinesFromUniversalCreateBS$.next({ headlines });
          this.store.dispatch(HeadlinesStateActions.createForSegment({ headline }));
        }
        if (itemType === ItemType.cascadedMessage) {
          this.newCascadingMessageFromUniversalCreateBS$.next({ headlines });
          this.store.dispatch(CascadingMessagesStateActions.createCascadedForSegment({ cascadingMessage: headline }));
        }
      }),
      catchError((e: unknown) => this.errorService.notify(e, `Create failed. Please try again.`))
    );
  }

  deleteHeadline(headlineId: string): Observable<void> {
    return this.http.delete<void>(`${this.headlinesApi}/${headlineId}`).pipe(
      tap(_ => this.notifyService.notify(`Delete successful`)),
      catchError((e: unknown) => this.errorService.notify(e, `Delete failed. Please try again.`))
    );
  }

  setArchived(id: string, archived: boolean): Observable<Headline> {
    return this.http.patch<Headline>(`${this.headlinesApi}/${id}/${archived ? 'Archive' : 'UnArchive'}`, {}).pipe(
      tap(_ => this.notifyService.notify(`${archived ? 'Archive' : 'Unarchive'} successful`)),
      catchError((e: unknown) =>
        this.errorService.notify(e, `${archived ? 'Archive' : 'Unarchive'} failed. Please try again.`)
      )
    );
  }

  /** Archives completed Headlines and Cascading Messages */
  archiveAllCompleted(teamId: string): Observable<{ numOfArchivedHeadlines: number }> {
    return this.http
      .patch<{ numOfArchivedHeadlines: number }>(`${this.headlinesApi}/Teams/${teamId}/ArchiveCompleted`, {})
      .pipe(catchError((e: unknown) => this.errorService.notify(e, `Archive failed. Please try again.`)));
  }

  setCompleted(id: string, completed: boolean, completedInMeeting: string = null): Observable<Partial<Headline>> {
    return this.http
      .post<Partial<Headline>>(
        `${this.headlinesApi}/${id}/${completed ? 'Complete' : 'UnComplete'}`,
        completedInMeeting && completed ? { completedInMeeting } : null
      )
      .pipe(
        tap(_ => this.notifyService.notify(`${completed ? 'Completed' : 'Uncompleted'} successful`)),
        catchError((e: unknown) =>
          this.errorService.notify(e, `${completed ? 'Completed' : 'Uncompleted'} failed. Please try again.`)
        )
      );
  }

  handleMessageWithApiGet(message: ReceivedRealtimeMessage): void {
    // Get the required object from API and treat as a pubnub message
    switch (message.originalMessageType) {
      case HeadlineMessageType.headline:
      case HeadlineMessageType.new:
      case HeadlineMessageType.archive:
        this.getHeadlineById((message.document as Headline)._id).subscribe({
          next: (headline: Headline) => {
            this.channelService.messageReceived$.next({
              messageType: message.originalMessageType,
              document: headline,
              isCascadingMessage: headline.isCascadedMessage,
            } as ReceivedRealtimeMessage);
          },
        });
        break;
    }
  }

  sanitizeMessage(message: RealtimeMessage): RealtimeMessage {
    const sanitizedMessage = cloneDeep(message);
    //TODO NEXT: do I even need to sanitize headline messages anymore
    //After conclude is refactored
    switch (sanitizedMessage.messageType) {
      case HeadlineMessageType.headline:
      case HeadlineMessageType.new:
      case HeadlineMessageType.archive:
        //TODO NEXT: check is cascadedFromTeam and team are still needed
        //TODO NEXT: do I still need clone deep after removing cascadedFromTeam and team
        //After conclude is refactored and old headlines are removed
        sanitizedMessage.document.cascadedFromTeam = null;
        sanitizedMessage.document.team = null;
        break;
    }

    return sanitizedMessage;
  }

  downloadExcel(filterBy: HeadlinesFilterBy, sortOptions: ExcelHeadlinesAndCMsSortOptions): Observable<ArrayBuffer> {
    const body = {
      teamId: filterBy.teamId,
      archived: filterBy.archived,
      searchText: filterBy.searchText,
      ...(sortOptions ? { sortOptions } : null),
      timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    return this.http
      .post(`${this.headlinesApi}/Excel`, body, {
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'arraybuffer',
      })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(e, `There was a problem creating this Excel file. Please try again.`)
        )
      );
  }
}
