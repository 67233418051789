import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { selectCurrentUserIsManagerOrAbove } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { BillingStateActions } from '@ninety/ui/legacy/state/app-global/billing/billing-state.actions';
import { selectIsFreeTier } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import {
  selectDaysLeftInTrial,
  selectIsTrialSubscription,
} from '@ninety/ui/legacy/state/app-global/company/subscription/subscription-state.selectors';

import { GuideActions } from '../../_state/guide.actions';
import { GuideSelectors } from '../../_state/guide.selectors';
import { Phase } from '../../models/phase';

@Component({
  selector: 'ninety-phase-list',
  templateUrl: './phase-list.component.html',
  styleUrls: ['./phase-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhaseListComponent {
  protected readonly title$ = this.store.select(GuideSelectors.selectTitle);
  protected readonly subtitle$ = this.store.select(GuideSelectors.selectSubtitle);
  protected readonly phases$ = this.store.select(GuideSelectors.selectPhases);
  protected readonly isTrialing$ = this.store.select(selectIsTrialSubscription);
  protected readonly daysLeftInTrial$ = this.store.select(selectDaysLeftInTrial);
  protected readonly isManagerOrAbove$ = this.store.select(selectCurrentUserIsManagerOrAbove);
  protected readonly isFreeTier$ = this.store.select(selectIsFreeTier);

  constructor(private readonly store: Store, private readonly router: Router) {}

  close(): void {
    this.store.dispatch(GuideActions.closeGuide());
  }

  selectPhase(phase: Phase): void {
    if (phase.locked) return;
    this.store.dispatch(GuideActions.selectPhase({ phase }));
  }

  openPricingTierDialog(): void {
    this.store.dispatch(BillingStateActions.openPricingTierDialog());
  }
}
