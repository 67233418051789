import { createSelector } from '@ngrx/store';

import { BrandingTheme, defaultBrandingTheme } from '../../../_shared/models/branding';
import { BusinessOperatingSystem } from '../../../_shared/models/company/business-operating-system.enum';
import {
  FeatureAccess,
  FeatureAccessLevel,
  NinetyFeatures,
  PricingTier,
  PricingTiers,
} from '../../../_shared/models/company/company-pricing-tiers';
import { loginDomainPartnerConfigurations } from '../../../_shared/models/login/login-domain-partner-configurations';
import { FeatureFlagKeys } from '../../app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '../../app-entities/feature-flag/feature-flag-state.selectors';
import { selectUrl } from '../../route.selectors';
import {
  selectAnonymousAffiliatePartner,
  selectAnonymousPartnerConfiguration,
} from '../anonymous/anonymous-state.selectors';
import { CompanyStateKey, selectGlobalAppState } from '../index';

export const selectCompanyState = createSelector(selectGlobalAppState, globalState =>
  globalState ? globalState[CompanyStateKey] : null
);

export const selectCompany = createSelector(selectCompanyState, state => state);
export const selectCompanyId = createSelector(selectCompany, state => state?._id);
export const selectCompanySltTeamId = createSelector(selectCompany, state => state?.seniorLeadershipTeamId);

export const selectCompanyName = createSelector(selectCompany, company => company.name);

export const selectBos = createSelector(selectCompany, company => company.bos);

export const selectTeamTodosTutorialVideoId = createSelector(selectBos, curBos =>
  curBos === BusinessOperatingSystem.eos ? '76yX5iUSWbNridBvdDnrDu' : 'qhmXEU25tPxePN9HuRok3w'
);

export const selectRocksTutorialVideoId = createSelector(selectBos, curBos =>
  curBos === BusinessOperatingSystem.eos ? 'cp3gT2NNUE8ERdKFdpbivc' : 'EJanjZTfqrixfd9jA2T22R'
);

export const selectCompanyAgendas = createSelector(selectCompany, company => company?.meetingAgendas);

export const selectIsPinnacleCompany = createSelector(selectBos, bos => bos === BusinessOperatingSystem.pinnacle);

export const selectIsNinetyOsCompany = createSelector(selectBos, bos => bos === BusinessOperatingSystem.ninetyOS);

export const selectIsTrialExpired = createSelector(selectCompany, company =>
  company.trialingUntil ? new Date(company.trialingUntil).getTime() < Date.now() : false
);
export const selectIsTrialingLegacy = createSelector(selectIsTrialExpired, isTrialExpired => !isTrialExpired);

export const selectCompanyColorBranding = createSelector(
  selectUrl,
  selectCompany,
  selectAnonymousAffiliatePartner,
  selectAnonymousPartnerConfiguration,
  (url, company, affiliatePartnerCompany, partnerConfiguration) => {
    const theme: BrandingTheme = {
      ...defaultBrandingTheme,
    };

    // If we're on a login path, always use the subdomain or affiliate code colors
    if (url && url.startsWith('/login')) {
      if (affiliatePartnerCompany && affiliatePartnerCompany.colorBranding) {
        theme.properties['--primaryAccent'] = affiliatePartnerCompany.colorBranding.primaryAccent;
        theme.properties['--secondaryAccent'] = affiliatePartnerCompany.colorBranding.secondaryAccent;
        return theme;
      }
      if (partnerConfiguration) {
        theme.properties['--primaryAccent'] = partnerConfiguration.primaryAccent;
        theme.properties['--secondaryAccent'] = partnerConfiguration.secondaryAccent;
        return theme;
      }
    }

    if (company?.settings?.colorBranding?.active) {
      theme.properties['--primaryAccent'] = company.settings.colorBranding.primaryAccent;
      theme.properties['--secondaryAccent'] = company.settings.colorBranding.secondaryAccent;
      return theme;
    }

    if (company?.bos) {
      for (const key in loginDomainPartnerConfigurations) {
        const config = loginDomainPartnerConfigurations[key];
        if (config.bos === company.bos) {
          theme.properties['--primaryAccent'] = config.primaryAccent;
          theme.properties['--secondaryAccent'] = config.secondaryAccent;
          return theme;
        }
      }
    }

    // Use the default;
    return theme;
  }
);

export const selectCompanySettings = createSelector(selectCompany, company => company.settings);
export const selectCompanySettingsAgreementBasedTodos = createSelector(
  selectCompanySettings,
  settings => settings?.agreementBasedTodos
);

export const selectIsAgreementsBasedTodosCompany = createSelector(
  selectCompanySettingsAgreementBasedTodos,
  agreementBasedCompany => agreementBasedCompany
);

export const selectCompanyMasterySettings = createSelector(selectCompanySettings, settings => settings?.mastery);

export const selectIsOrgChartPrivate = createSelector(
  selectCompanySettings,
  settings => settings?.privateAccountabilityChart
);

/** =============================================
 * Commercial Model
 * ============================================== */

/** Some things might not be tied to tiers, but whether the commercial model is active */
export const selectCommercialModelActive = createSelector(
  selectFeatureFlag(FeatureFlagKeys.commercialModelActive),
  isActive => isActive
);

export const selectCompanyCreatedAfterPricingGoLive = createSelector(
  selectFeatureFlag(FeatureFlagKeys.webPricingGoLiveDate),
  selectCompany,
  (goLiveDate, company) => {
    if (goLiveDate && company && company._id) {
      return getObjectIdCreationDate(company._id) > new Date(goLiveDate);
    }
  }
);

export const selectTempPricingTier = createSelector(
  selectFeatureFlag(FeatureFlagKeys.webTempPricingTier),
  (pricingTier): PricingTier => {
    if (Object.values(PricingTier).includes(pricingTier)) return pricingTier;
    return PricingTier.Thrive;
  }
);

export const selectIsFreeTier = createSelector(selectTempPricingTier, pricingTier => pricingTier === PricingTier.Free);

/** temporary selector for the mastery and process access.
 *
 * Mastery and process will never be seen by companies created after commercial model goes live (go live date)
 * For companies created before, only thrive tier will have write access
 * */
export const selectMasteryAndProcessAccess = createSelector(
  selectTempPricingTier,
  selectCompanyCreatedAfterPricingGoLive,
  (pricingTier, createdAfterGoLive): FeatureAccess[] => {
    if (createdAfterGoLive) return [];
    const access = pricingTier === PricingTier.Thrive ? FeatureAccessLevel.write : FeatureAccessLevel.read;
    return [
      { featureId: NinetyFeatures.mastery, access },
      { featureId: NinetyFeatures.process, access },
    ];
  }
);

/** returns a list of features that the company has access to based on the pricing tier */
export const selectFeatureAccess = createSelector(
  selectTempPricingTier,
  selectMasteryAndProcessAccess,
  (pricingTier, masteryAndProcessAccess): FeatureAccess[] => {
    return [...PricingTiers[pricingTier], ...masteryAndProcessAccess];
  }
);

export const selectFeatureEnabled = (featureId: NinetyFeatures) =>
  createSelector(selectFeatureAccess, (featureAccess): boolean => featureAccess.some(f => f.featureId === featureId));

export const selectFeatureAccessLevel = (featureId: NinetyFeatures) =>
  createSelector(selectFeatureAccess, featureAccess => featureAccess.find(f => f.featureId === featureId).access);

export const selectIsFeatureReadOnly = (featureId: NinetyFeatures) =>
  createSelector(selectFeatureAccessLevel(featureId), access => access === FeatureAccessLevel.read);

export const selectHasFeatureWriteAccess = (featureId: NinetyFeatures) =>
  createSelector(selectFeatureAccessLevel(featureId), access => access === FeatureAccessLevel.write);

//custom meeting selector for weekly Only on essential tier
export const selectWeeklyMeetingsOnlyFeature = createSelector(
  selectFeatureAccess,
  featureAccess =>
    featureAccess.some(f => f.featureId === NinetyFeatures.weeklyMeetings) &&
    !featureAccess.some(f => f.featureId === NinetyFeatures.allMeetings)
);

function getObjectIdCreationDate(objectId: string): Date {
  const timestamp = parseInt(objectId.substring(0, 8), 16) * 1000;
  return new Date(timestamp);
}
