<terra-quick-filter
  id="{{ select.id }}-trigger"
  [ngModel]="_selectedQfTeam"
  key="Team"
  (selectionChange)="onSelectValue($event)"
  (openedChange)="onOpenChange($event)"
  [compareWith]="_teamsAreSame"
  [disabled]="select.disabled || select.readonly"
  maxHeight="350"
  data-cy="team-pill-select">
  <terra-search-input
    [ngModel]="_teamSearchInput"
    placeholder="Search teams..."
    (ngModelChange)="_handleSearchTermChanged($event)"
    id="{{ select.id + '-input' }}"></terra-search-input>
  <terra-description *ngIf="fuseProvider.fuse.hasNoResults$ | ngrxPush" class="no-search-results">
    {{ select.noResultsText }}
  </terra-description>
  <terra-option
    [style.display]="shouldShowTeamAll && !(fuseProvider.fuse.hasSearchTerm$ | ngrxPush) ? '' : 'none'"
    [value]="teamAll"
    data-cy="all-team-option"
    >All</terra-option
  >
  <ng-container *ngrxLet="fuseProvider.fuse.managedOptions$ as teams">
    <terra-option
      *ngFor="let option of teams; trackBy: trackBy"
      [style.display]="option.visible ? '' : 'none'"
      [value]="option.value"
      attr.data-cy="{{ option.value.name }}-team-option">
      {{ option.value.name }}</terra-option
    >
  </ng-container>
</terra-quick-filter>
