import { CdkAccordion, CdkAccordionItem, CdkAccordionModule } from '@angular/cdk/accordion';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import cloneDeep from 'lodash/cloneDeep';

import { TerraDividerModule, TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';

import {
  DescribableInputComponent,
  DescribeSuperContentDirective,
} from '../text-input/components/describable-input/describable-input.component';
import { AutoHeightDirective } from '../text-input/directives/auto-height.directive';
import { NinetyInputDirective } from '../text-input/directives/ninety-input.directive';

export interface ValueDescription {
  _id?: string;
  value: string;
  description?: string;
  ordinal: number;
}

export function createValueDescription(parts: Partial<ValueDescription>): ValueDescription {
  return {
    _id: null,
    value: null,
    description: null,
    ordinal: 0,
    ...parts,
  };
}

export class ElementIdManager {
  private id = 0;

  constructor(public readonly prefix: string) {}

  public getId(): string {
    return `${this.prefix}-${this.id++}`;
  }

  public isOfIdFormat(id: string): boolean {
    return id.startsWith(this.prefix);
  }
}

/** A highly opinionated, model-driven form control used to manage a list of value-description pairs. */
@Component({
  selector: 'ninety-value-description-list',
  standalone: true,
  imports: [
    CommonModule,
    CdkAccordionModule,
    NinetyInputDirective,
    FormsModule,
    ButtonComponent,
    CdkDragHandle,
    CdkDropList,
    CdkDrag,
    DescribableInputComponent,
    DescribeSuperContentDirective,
    MatDividerModule,
    MatTooltipModule,
    AutoHeightDirective,
    TerraDividerModule,
    TerraIconModule,
  ],
  templateUrl: './value-description-list.component.html',
  styleUrls: ['./value-description-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ValueDescriptionListComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ValueDescriptionListComponent,
    },
  ],
})
export class ValueDescriptionListComponent implements ControlValueAccessor, Validator {
  // Static Interface
  public static titleElementIdManager = new ElementIdManager('title-input');
  public static thisElementIdManager = new ElementIdManager('ninety-value-description-list');

  // Instance

  /** The ID of this component's host. */
  @Input() id = ValueDescriptionListComponent.thisElementIdManager.getId();

  @Input() headerLabel = 'Title and description';
  @Input() addLabel = 'Add new';

  /** Disable this component. Can still expand, but can't edit or create. */
  @Input({ transform: coerceBooleanProperty })
  @HostBinding('class.ninety-disabled')
  disabled: BooleanInput = false;

  /** Mark this component as readonly. Can still expand, but can't edit or create. */
  @Input({ transform: coerceBooleanProperty })
  @HostBinding('class.ninety-readonly')
  readonly: BooleanInput = false;

  /**
   * Pass in the value descriptions to be displayed. Should not be used in conjunction with either Form module
   * (use one or the other)
   */
  @Input() set valueDescriptions(value: readonly ValueDescription[]) {
    this.externalSetOfAllDescriptions(value);
  }

  /** The value descriptions this component is managing */
  get valueDescriptions(): readonly ValueDescription[] {
    return this._valueDescriptions;
  }

  /**
   * Emits when the value descriptions change.Should not be used in conjunction with either Form module
   * (use one or the other)
   */
  @Output() valueDescriptionsChange = new EventEmitter<readonly ValueDescription[]>();

  /** Accurately report when this component is touched to any Form module. */
  @HostListener('click') protected onClick() {
    if (this.onTouch && !this.disabled && !this.readonly) this.onTouch();
  }

  @ViewChild(CdkAccordion) protected accordion: CdkAccordion;
  @ViewChildren(CdkAccordionItem) protected accordionItems: QueryList<CdkAccordionItem>;
  @ViewChildren('labelInput', { read: ElementRef }) protected accordionItemElements: QueryList<ElementRef>;

  /** The internal representation of the value descriptions of this component. */
  private _valueDescriptions: readonly ValueDescription[] = [];

  /** The number of expanded items in the accordion. Used to chose between expand all and collapse all. */
  private expandedCount = 0;

  /** A set of title inputs that have been touched (by their index). */
  private touched = new Set<number>();

  constructor(private cdr: ChangeDetectorRef) {}

  // Public API

  get allExpanded() {
    return this.expandedCount === this._valueDescriptions.length;
  }

  get anyExpanded() {
    return this.expandedCount > 0;
  }

  get allCollapsed() {
    return this.expandedCount === 0;
  }

  /**
   * Add a new value description to the control. Calling this method will emit on the ControlValueAccessor. If an ID
   * is not provided, a new one will be generated. See {@link ValueDescriptionListComponent.titleElementIdManager}
   */
  add(value: ValueDescription): void {
    if (this.disabled || this.readonly) {
      const problem = this.disabled ? 'disabled' : 'readonly';
      // console.error({ message: 'Cannot programmatically add a value description when the component is ' + problem });
      return;
    }

    if (!value._id) value._id = ValueDescriptionListComponent.titleElementIdManager.getId();

    // if (value.ordinal || value.ordinal === 0) {
    if ('ordinal' in value) {
      const copy = [...this._valueDescriptions, value];
      this.setListAndSort(copy);
    } else {
      this._valueDescriptions = [...this._valueDescriptions, value];
    }

    this.updateObserversAfterValueChange();

    this.cdr.detectChanges();

    this.accordionItems.last.expanded = true;
    this.accordionItemElements.last.nativeElement.focus();
    this.expandedCount++;
  }

  /** Remove a value description from the control. Calling this method will emit on the ControlValueAccessor. */
  removeAt(index: number) {
    if (this.disabled || this.readonly) {
      const problem = this.disabled ? 'disabled' : 'readonly';
      // console.error({ message: 'Cannot programmatically remove a value description when the component is ' + problem });
      return;
    }
    if (!this._valueDescriptions.length) throw new Error('Cannot remove from an empty list');
    if (index < 0 || index >= this._valueDescriptions.length) throw new Error('Index out of bounds');

    const copy = [...this._valueDescriptions];
    copy.splice(index, 1);
    this._valueDescriptions = copy;
    this.touched.delete(index);

    this.updateObserversAfterValueChange();
    this.expandedCount--;
  }

  /** Returns true if the specific title input element has ever been focused. */
  titleInputForIndexIsTouched(index: number) {
    return this.touched.has(index);
  }

  /** Returns true if the specific title input element has an error. */
  elementHasError(index: number): boolean {
    return !this._valueDescriptions[index].value?.length;
  }

  /** Expands all items in the accordion. */
  expandAll() {
    this.accordion.openAll();
    this.expandedCount = this.valueDescriptions.length;
  }

  /** Collapses all items in the accordion. */
  collapseAll() {
    this.accordion.closeAll();
    this.expandedCount = 0;
  }

  // Control Value Accessor

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: ValueDescription[]): void {
    this.externalSetOfAllDescriptions(value);
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(_control: AbstractControl<ValueDescription>): ValidationErrors {
    return this._valueDescriptions.some(v => !v.value?.length) ? { emptyValue: true } : null;
  }

  // Protected Template Internals

  protected trackByFn(_i: number, item: ValueDescription) {
    return item._id;
  }

  protected setValueFromInput(valueText: string, index: number) {
    const copy = [...this._valueDescriptions];
    copy[index].value = valueText;
    this._valueDescriptions = copy;

    this.updateObserversAfterValueChange();
  }

  protected setDescriptionFromInput(descriptionText: string, index: number) {
    const copy = [...this._valueDescriptions];
    copy[index].description = descriptionText;
    this._valueDescriptions = copy;

    this.updateObserversAfterValueChange();
  }

  protected createNewValueDescription() {
    const last = this._valueDescriptions.at(-1);
    const ordinal = last ? last.ordinal + 1 : 0;
    this.add(createValueDescription({ value: '', description: '', ordinal }));
  }

  protected toggle(item: CdkAccordionItem): void {
    if (item.expanded) this.expandedCount--;
    else this.expandedCount++;

    item.toggle();
  }

  protected titleInputOnBlur(index: number) {
    this.touched.add(index);
    this.onTouch();
  }

  protected titleInputHasErrorAtIndex(index: number): boolean {
    return this.elementHasError(index) && this.titleInputForIndexIsTouched(index);
  }

  protected reorder($event: CdkDragDrop<any, any>) {
    const clone = cloneDeep(this._valueDescriptions) as ValueDescription[]; // Cast to strip readonly
    moveItemInArray(clone, $event.previousIndex, $event.currentIndex);
    clone.forEach((v, i) => (v.ordinal = i));
    this._valueDescriptions = clone;

    this.updateObserversAfterValueChange();
  }

  private externalSetOfAllDescriptions(value: readonly ValueDescription[]) {
    const clone = this.setListAndSort(value);
    this.expandedCount = clone.length;
    this.touched.clear(); // Ensure that any external write marks the component as "pristine"
  }

  private updateObserversAfterValueChange() {
    this.valueDescriptionsChange.emit(this.valueDescriptions);

    if (this.onChange) {
      this.onChange(this.valueDescriptions);
      this.onTouch();
    }
  }

  private setListAndSort(value: readonly ValueDescription[]) {
    const clone = [...value];
    clone.sort((a, b) => a.ordinal - b.ordinal);
    this._valueDescriptions = clone;
    return clone;
  }
}
