import { moveItemInArray } from '@angular/cdk/drag-drop';
import { EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { cloneDeep as _cloneDeep } from 'lodash';

import { Milestone, Rock, RockLevelCode, RockStatusCode } from '@ninety/ui/legacy/shared/index';
import { RockConstants } from '@ninety/ui/legacy/shared/models/rocks/rock-constants';
import { UserListModel } from '@ninety/ui/legacy/state/app-entities/user-list/api/user-list.model';

import { RocksStateActions } from './rocks-v2-state.actions';
import {
  RockModuleStateModel,
  milestonesStateAdapter,
  rocksMilestonesInitialState,
  rocksStateAdapter,
  rocksStateAdapterForCompany,
  rocksStateAdapterForDepartment,
  rocksStateAdapterForUsersNotOnTeam,
} from './rocks-v2-state.model';

export const RockStateReducer = createReducer(
  rocksMilestonesInitialState,

  on(
    RocksStateActions.filterByUser,
    (state, { userId }): RockModuleStateModel => ({
      ...state,
      filters: { ...state.filters, userId },
    })
  ),

  on(RocksStateActions.resetStore, (): RockModuleStateModel => rocksMilestonesInitialState),

  on(RocksStateActions.filterByTeam, state => {
    const rocks = rocksStateAdapter.setAll([], state.rocks);
    const milestones = milestonesStateAdapter.setAll([], state.milestones);
    return { ...state, rocks, milestones, totalCount: 0, loading: false, error: false };
  }),

  on(RocksStateActions.getRocksAndMilestonesSuccess, (state, { rocksAndMilestones }) => {
    const rocks = rocksStateAdapter.setAll(rocksAndMilestones.rocks, state.rocks);
    const milestones = milestonesStateAdapter.setAll(rocksAndMilestones.milestones, state.milestones);
    return { ...state, rocks, milestones, totalCount: rocksAndMilestones.totalCount, loading: false, error: false };
  }),

  on(RocksStateActions.getArchivedRocksSuccess, (state, { rocks, totalCount }) => {
    const rocksState = rocksStateAdapter.setAll(rocks, state.rocks);
    const companyRocksState = rocksStateAdapter.setAll(rocks, state.rocksForCompany);
    const allMilestones: Milestone[] = [];

    for (const rock of rocks) {
      const milestones = rock.milestones;

      if (milestones?.length) {
        allMilestones.push(...milestones);
      }
    }
    const milestonesState = milestonesStateAdapter.setAll(allMilestones, state.milestones);

    return {
      ...state,
      ...{ rocks: rocksState },
      ...{ rocksForCompany: companyRocksState },
      ...{ milestones: milestonesState },
      totalCount,
      loading: false,
      archived: true,
      error: false,
    };
  }),
  on(RocksStateActions.getRocksByTeamSuccess, (state, { rocks, users, team, company }) => {
    const rocksForUserNotOnTeam: Rock[] = rocks.filter(
      rock => !users.find((u: UserListModel) => u._id === rock.userId)
    );
    const companyRocks =
      team._id === company.seniorLeadershipTeamId
        ? rocks.filter(
            rock => rock.levelCode === RockLevelCode.companyAndDepartment || rock.levelCode === RockLevelCode.company
          )
        : [];
    const departmentRocks =
      team._id !== company.seniorLeadershipTeamId
        ? rocks.filter(
            rock => rock.levelCode === RockLevelCode.department || rock.levelCode === RockLevelCode.companyAndDepartment
          )
        : [];

    const allMilestones: Milestone[] = [];

    for (const rock of rocks) {
      const milestones = rock.milestones;

      if (milestones?.length) {
        allMilestones.push(...milestones);
      }
    }

    const userRocks = rocks.filter(rock => users.find((u: UserListModel) => u._id === rock.userId));
    const rocksState = rocksStateAdapter.setAll(userRocks, state.rocks);
    const milestonesState = milestonesStateAdapter.setAll(allMilestones, state.milestones);
    const companyRocksState = rocksStateAdapterForCompany.setAll(companyRocks, state.rocksForCompany);
    const departmentRocksState = rocksStateAdapterForDepartment.setAll(departmentRocks, state.rocksForDepartment);
    const rocksForUsersNotOnTeamState = rocksStateAdapterForUsersNotOnTeam.setAll(
      rocksForUserNotOnTeam,
      state.rocksForUsersNotOnTeam
    );
    return {
      ...state,
      ...{ rocks: rocksState },
      ...{ milestones: milestonesState },
      ...{ rocksForCompany: companyRocksState },
      ...{ rocksForDepartment: departmentRocksState },
      ...{ rocksForUsersNotOnTeam: rocksForUsersNotOnTeamState },
      totalCount: rocks.length,
      loading: false,
      currentTeam: team,
      error: false,
    };
  }),

  on(
    RocksStateActions.getRocksAndMilestonesFailed,
    (state): RockModuleStateModel => ({
      ...state,
      error: true,
      loading: false,
    })
  ),
  on(RocksStateActions.addRock, (state, { rock }) => {
    const rocks = rocksStateAdapter.addOne(rock, state.rocks);
    if (state.rocksV3) {
      if (rock.levelCode !== RockLevelCode.user) {
        let rocksForCompany = state.rocksForCompany;
        let rocksForDepartment = state.rocksForDepartment;

        if (rocksForCompany.ids.length > 0) {
          if (rock.levelCode === RockLevelCode.companyAndDepartment || rock.levelCode === RockLevelCode.company) {
            rocksForCompany = rocksStateAdapterForCompany.addOne(rock, state.rocksForCompany);
          }
        } else {
          if (rock.levelCode === RockLevelCode.department || rock.levelCode === RockLevelCode.companyAndDepartment) {
            rocksForDepartment = rocksStateAdapterForDepartment.addOne(rock, state.rocksForDepartment);
          }
        }

        return {
          ...state,
          rocks,
          rocksForCompany,
          rocksForDepartment,
          totalCount: state.totalCount + 1,
          loading: false,
        };
      }

      return { ...state, rocks, totalCount: state.totalCount + 1, loading: false };
    }
    return { ...state, rocks, totalCount: state.totalCount + 1, loading: false };
  }),
  on(
    RocksStateActions.openRockInDetailView,
    (state, { rockId }): RockModuleStateModel => ({
      ...state,
      selectedRockId: rockId,
      selectedMilestoneId: null,
    })
  ),
  on(
    RocksStateActions.openRockV2InDetailView,
    (state, { rockId }): RockModuleStateModel => ({
      ...state,
      selectedRockId: rockId,
      selectedMilestoneId: null,
    })
  ),
  on(
    RocksStateActions.openRockV3InDetailView,
    (state, { rockId }): RockModuleStateModel => ({
      ...state,
      selectedRockId: rockId,
      selectedMilestoneId: null,
    })
  ),
  on(RocksStateActions.updateRock, (state, { update, rock: newRock }) => {
    if (state.rocksV3) {
      const newState = updateRockV3(state, update, newRock);
      return { ...newState };
    }
    const rocks = rocksStateAdapter.updateOne(update, state.rocks);
    return { ...state, rocks, loading: false };
  }),
  on(RocksStateActions.updateRockAndSort, RocksStateActions.updateRockAndSortLocal, (state, { update }) => {
    const rocks = rocksStateAdapter.updateOne(update, state.rocks);
    return { ...state, rocks, loading: false };
  }),
  on(RocksStateActions.updateRockLocal, (state, { update, isUserRock }) => {
    if (!state.rocks.entities[update.id] && !state.rocksForUsersNotOnTeam.entities[update.id]) {
      if (isUserRock) {
        const rocks = rocksStateAdapter.addOne(update.changes, state.rocks);
        if (update.changes.levelCode !== RockLevelCode.user) {
          let rocksForCompany = state.rocksForCompany;
          let rocksForDepartment = state.rocksForDepartment;

          if (rocksForCompany.ids.length > 0) {
            if (
              update.changes.levelCode === RockLevelCode.companyAndDepartment ||
              update.changes.levelCode === RockLevelCode.company
            ) {
              rocksForCompany = rocksStateAdapterForCompany.addOne(update.changes, state.rocksForCompany);
            }
          } else {
            if (
              update.changes.levelCode === RockLevelCode.department ||
              update.changes.levelCode === RockLevelCode.companyAndDepartment
            ) {
              rocksForDepartment = rocksStateAdapterForDepartment.addOne(update.changes, state.rocksForDepartment);
            }
          }

          return { ...state, rocks, rocksForCompany, rocksForDepartment };
        }

        return { ...state, rocks };
      }
      const rocksForUsersNotOnTeam = rocksStateAdapterForUsersNotOnTeam.addOne(
        update.changes,
        state.rocksForUsersNotOnTeam
      );
      if (update.changes.levelCode !== RockLevelCode.user) {
        let rocksForCompany = state.rocksForCompany;
        let rocksForDepartment = state.rocksForDepartment;

        if (rocksForCompany.ids.length > 0) {
          if (
            update.changes.levelCode === RockLevelCode.companyAndDepartment ||
            update.changes.levelCode === RockLevelCode.company
          ) {
            rocksForCompany = rocksStateAdapterForCompany.addOne(update.changes, state.rocksForCompany);
          }
        } else {
          if (
            update.changes.levelCode === RockLevelCode.companyAndDepartment ||
            update.changes.levelCode === RockLevelCode.department
          ) {
            rocksForDepartment = rocksStateAdapterForDepartment.addOne(update.changes, state.rocksForDepartment);
          }
        }

        return { ...state, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam };
      }
      return { ...state, rocksForUsersNotOnTeam };
    }
    const entityStates = [state.rocks, state.rocksForUsersNotOnTeam];
    const [rocks, rocksForUsersNotOnTeam] = [rocksStateAdapter, rocksStateAdapterForUsersNotOnTeam].map(
      (adapter, index) => adapter.updateOne(update, entityStates[index])
    );
    if (update.changes.levelCode !== RockLevelCode.user) {
      let rocksForCompany = state.rocksForCompany;
      let rocksForDepartment = state.rocksForDepartment;

      if (rocksForCompany.ids.length > 0) {
        if (!rocksForCompany.entities[update.id]) {
          rocksForCompany = rocksStateAdapterForCompany.addOne(rocks.entities[update.id], state.rocksForCompany);
        } else {
          rocksForCompany = rocksStateAdapterForCompany.updateOne(update, state.rocksForCompany);
        }
      } else {
        if (!rocksForDepartment.entities[update.id]) {
          rocksForDepartment = rocksStateAdapterForDepartment.addOne(
            rocks.entities[update.id],
            state.rocksForDepartment
          );
        } else {
          rocksForDepartment = rocksStateAdapterForDepartment.updateOne(update, state.rocksForDepartment);
        }
      }
      return { ...state, rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam };
    } else if (update.changes.levelCode === RockLevelCode.user && state.rocksForCompany.entities[update.id]) {
      const rocksForCompany = rocksStateAdapterForCompany.removeOne(update.id as string, state.rocksForCompany);
      return { ...state, rocks, rocksForCompany, rocksForUsersNotOnTeam };
    } else if (update.changes.levelCode === RockLevelCode.user && state.rocksForDepartment.entities[update.id]) {
      const rocksForDepartment = rocksStateAdapterForDepartment.removeOne(
        update.id as string,
        state.rocksForDepartment
      );
      return { ...state, rocks, rocksForDepartment, rocksForUsersNotOnTeam };
    }

    {
      const entityStates = [state.rocksForCompany, state.rocksForDepartment];

      const [rocksForCompany, rocksForDepartment] = [rocksStateAdapterForCompany, rocksStateAdapterForDepartment].map(
        (adapter, index) => adapter.updateOne(update, entityStates[index])
      );
      return { ...state, rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam };
    }
  }),
  on(
    RocksStateActions.updateRockAndRemoveFromCompanyRockList,
    RocksStateActions.updateRockAndRemoveFromCompanyRockListLocal,
    RocksStateActions.updateRockAndRemoveFromDepartmentRockList,
    RocksStateActions.updateRockAndRemoveFromDepartmentRockListLocal,
    (state, { update }) => {
      const rocks = rocksStateAdapter.updateOne(update, state.rocks);
      const entityStates = [state.rocksForCompany, state.rocksForDepartment];
      const [rocksForCompany, rocksForDepartment] = [rocksStateAdapterForCompany, rocksStateAdapterForDepartment].map(
        (adapter, index) => adapter.removeOne(update.id as string, entityStates[index])
      );
      return { ...state, rocks, rocksForCompany, rocksForDepartment };
    }
  ),
  on(
    RocksStateActions.updateRockAndAddToCompanyRockList,
    RocksStateActions.updateRockAndAddToCompanyRockListLocal,
    RocksStateActions.updateRockAndAddToDepartmentRockList,
    RocksStateActions.updateRockAndAddToDepartmentRockListLocal,
    (state, { update }) => {
      const rocks = rocksStateAdapter.updateOne(update, state.rocks);
      const entityStates = [state.rocksForCompany, state.rocksForDepartment];

      const [rocksForCompany, rocksForDepartment] = [rocksStateAdapterForCompany, rocksStateAdapterForDepartment].map(
        (adapter, index) => adapter.addOne(rocks.entities[update.id], entityStates[index])
      );
      return { ...state, rocks, rocksForCompany, rocksForDepartment };
    }
  ),
  on(RocksStateActions.updateOrdinals, RocksStateActions.updateOrdinalsLocal, (state, { rocks, ordinalType }) => {
    const updates = rocks.map(rock => ({ id: rock._id, changes: { [ordinalType]: rock[ordinalType] } }));

    const entityStates = [state.rocks, state.rocksForCompany, state.rocksForDepartment, state.rocksForUsersNotOnTeam];
    const [userRocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam] = [
      rocksStateAdapter,
      rocksStateAdapterForCompany,
      rocksStateAdapterForDepartment,
      rocksStateAdapterForUsersNotOnTeam,
    ].map((adapter, index) => adapter.updateMany(updates, entityStates[index]));
    return { ...state, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam, rocks: userRocks };
  }),

  on(RocksStateActions.toggleArchivedSuccess, (state, { rockId }) => {
    if (state.rocksV3) {
      return removeARock(state, rockId);
    }

    const rocks = rocksStateAdapter.removeOne(rockId, state.rocks);
    return { ...state, rocks, loading: false };
  }),
  on(RocksStateActions.deleteSuccess, (state, { rockId }) => {
    if (state.rocksV3) {
      return removeARock(state, rockId);
    }
    const rocks = rocksStateAdapter.removeOne(rockId, state.rocks);
    return { ...state, rocks, totalCount: state.totalCount - 1, loading: false };
  }),
  on(RocksStateActions.removeFromList, (state, { rockId }) => {
    if (state.rocksV3) {
      return removeARock(state, rockId);
    }
    const rocks = rocksStateAdapter.removeOne(rockId, state.rocks);
    return { ...state, rocks, totalCount: state.totalCount - 1, loading: false };
  }),
  on(RocksStateActions.removeCompleted, state => {
    function getCompleteRockIds(rocks: EntityState<Rock>): string[] {
      return Object.values(rocks.entities)
        .filter(rock => rock.statusCode === RockStatusCode.complete)
        .map(rock => rock._id);
    }

    const rockIds = [...getCompleteRockIds(state.rocks), ...getCompleteRockIds(state.rocksForUsersNotOnTeam)];

    const entityStates = [state.rocks, state.rocksForCompany, state.rocksForDepartment, state.rocksForUsersNotOnTeam];

    const [rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam] = [
      rocksStateAdapter,
      rocksStateAdapterForCompany,
      rocksStateAdapterForDepartment,
      rocksStateAdapterForUsersNotOnTeam,
    ].map((adapter, index) => adapter.removeMany(rockIds, entityStates[index]));

    return { ...state, rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam };
  }),
  on(RocksStateActions.deleteLocal, (state, { rockId }) => {
    if (state.rocksV3) {
      return removeARock(state, rockId);
    }
    const rocks = rocksStateAdapter.removeOne(rockId, state.rocks);
    return { ...state, rocks, totalCount: state.totalCount - 1, loading: false };
  }),
  on(RocksStateActions.addToList, (state, { rock }) => {
    const rocks = rocksStateAdapter.addOne(rock, state.rocks);
    return { ...state, rocks, totalCount: state.totalCount + 1, loading: false };
  }),
  on(
    RocksStateActions.filterByStatus,
    (state, { statusCode }): RockModuleStateModel => ({
      ...state,
      filters: { ...state.filters, statusCode },
      loading: true,
    })
  ),
  on(
    RocksStateActions.filterByTeam,
    RocksStateActions.setFilterByTeam,
    (state, { teamId }): RockModuleStateModel => ({
      ...state,
      //make sure state is clean and no all are set as string
      filters: { ...state.filters, teamId: teamId && teamId !== 'all' ? teamId : null },
      loading: true,
    })
  ),
  on(
    RocksStateActions.paginationChange,
    (state, { index, size }): RockModuleStateModel => ({
      ...state,
      pagination: { index, size },
    })
  ),
  on(
    RocksStateActions.sortChange,
    (state, { field, direction }): RockModuleStateModel => ({
      ...state,
      sort: { field, direction },
    })
  ),
  on(
    RocksStateActions.clearSort,
    (state): RockModuleStateModel => ({
      ...state,
      sort: { field: null, direction: null },
    })
  ),
  on(RocksStateActions.updateUserOrdinals, (state, { previousIndex, currentIndex }): RockModuleStateModel => {
    const rocksToBeOrdered = _cloneDeep(rocksStateAdapter.getSelectors().selectAll(state.rocks));

    moveItemInArray(rocksToBeOrdered, previousIndex, currentIndex);
    rocksToBeOrdered.map((rock, i) => (rock.userOrdinal = state.pagination.index * state.pagination.size + i));

    return {
      ...state,
      rocks: rocksStateAdapter.setAll(rocksToBeOrdered, state.rocks),
    };
  }),
  on(
    RocksStateActions.hydratePageSize,
    (state, { pageSize }): RockModuleStateModel => ({
      ...state,
      pagination: {
        ...state.pagination,
        size: pageSize,
      },
    })
  ),
  on(RocksStateActions.clearSelectedRock, (state): RockModuleStateModel => ({ ...state, selectedRockId: null })),
  on(
    RocksStateActions.clearSelectedMilestone,
    (state): RockModuleStateModel => ({ ...state, selectedMilestoneId: null })
  ),

  //Milestones
  on(RocksStateActions.updateMilestoneInStore, (state, { update }) => {
    const milestones = milestonesStateAdapter.updateOne(update, state.milestones);

    if (state.rocksV3 && milestones.entities[update.id]) {
      return updateMilestoneInRock(state, milestones, milestones.entities[update.id].rockId, update.id as string);
    } else {
      return { ...state, milestones, loading: false };
    }
  }),

  on(RocksStateActions.updateMilestone, (state, { update }) => {
    const milestones = milestonesStateAdapter.updateOne(update, state.milestones);
    return { ...state, milestones, loading: false };
  }),

  on(RocksStateActions.deleteMilestone, (state, { milestoneId, rockId }) => {
    const milestones = milestonesStateAdapter.removeOne(milestoneId, state.milestones);

    const entityStates = [state.rocks, state.rocksForCompany, state.rocksForDepartment, state.rocksForUsersNotOnTeam];
    const adapters = [
      rocksStateAdapter,
      rocksStateAdapterForCompany,
      rocksStateAdapterForDepartment,
      rocksStateAdapterForUsersNotOnTeam,
    ];

    const [rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam] = adapters.map((adapter, index) =>
      updateRockMilestones(adapter, rockId, milestoneId, entityStates[index])
    );

    return { ...state, milestones, rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam, loading: false };
  }),
  on(RocksStateActions.deleteMilestoneFromStore, (state, { milestoneId }) => {
    const rockId = state.milestones.entities[milestoneId]?.rockId;
    const milestones = milestonesStateAdapter.removeOne(milestoneId, state.milestones);
    if (state.rocksV3 && rockId) {
      const entityStates = [state.rocks, state.rocksForCompany, state.rocksForDepartment, state.rocksForUsersNotOnTeam];
      const adapters = [
        rocksStateAdapter,
        rocksStateAdapterForCompany,
        rocksStateAdapterForDepartment,
        rocksStateAdapterForUsersNotOnTeam,
      ];

      const [rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam] = adapters.map((adapter, index) =>
        updateRockMilestones(adapter, rockId, milestoneId, entityStates[index])
      );

      return { ...state, rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam, milestones };
    }
    return { ...state, milestones };
  }),
  on(RocksStateActions.addMilestoneToStore, (state, { milestone }) => {
    const milestones = milestonesStateAdapter.addOne(milestone, state.milestones);
    if (state.rocksV3) {
      function addMilestoneToRock(adapter, rockId, milestone, entityState) {
        return adapter.mapOne(
          {
            id: rockId,
            map: r => ({
              ...r,
              milestones: [...r.milestones, milestone].sort(
                (a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()
              ),
            }),
          },
          entityState
        );
      }

      const rockId = milestone.rockId;

      const entityStates = [state.rocks, state.rocksForCompany, state.rocksForDepartment, state.rocksForUsersNotOnTeam];
      const adapters = [
        rocksStateAdapter,
        rocksStateAdapterForCompany,
        rocksStateAdapterForDepartment,
        rocksStateAdapterForUsersNotOnTeam,
      ];

      const [rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam] = adapters.map((adapter, index) =>
        addMilestoneToRock(adapter, rockId, milestone, entityStates[index])
      );

      return { ...state, rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam, milestones };
    }

    return { ...state, milestones };
  }),
  on(RocksStateActions.addMilestoneToRockLocal, (state, { rock, milestone, editableRockType }) => {
    const milestones = milestonesStateAdapter.addOne(milestone, state.milestones);
    if (editableRockType) {
      if (editableRockType === RockConstants.userRocks) {
        const rocks = rocksStateAdapterForCompany.mapOne(
          { id: rock._id, map: r => ({ ...r, milestones: [...r.milestones, milestone] }) },
          state.rocks
        );
        return { ...state, rocks, milestones };
      } else {
        const rocksForUsersNotOnTeam = rocksStateAdapterForUsersNotOnTeam.mapOne(
          { id: rock._id, map: r => ({ ...r, milestones: [...r.milestones, milestone] }) },
          state.rocksForUsersNotOnTeam
        );
        return { ...state, rocksForUsersNotOnTeam, milestones };
      }
    } else {
      const entityStates = [state.rocks, state.rocksForCompany, state.rocksForDepartment, state.rocksForUsersNotOnTeam];

      const [rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam] = [
        rocksStateAdapter,
        rocksStateAdapterForCompany,
        rocksStateAdapterForDepartment,
        rocksStateAdapterForUsersNotOnTeam,
      ].map((adapter, index) =>
        adapter.mapOne(
          { id: rock._id, map: r => ({ ...r, milestones: [...r.milestones, milestone] }) },
          entityStates[index]
        )
      );
      return { ...state, rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam, milestones };
    }
  }),
  on(RocksStateActions.deleteMilestoneFromRockLocal, (state, { milestone }) => {
    const milestones = milestonesStateAdapter.removeOne(milestone._id, state.milestones);
    const rockId = milestone.rockId;

    const entityStates = [state.rocks, state.rocksForCompany, state.rocksForDepartment, state.rocksForUsersNotOnTeam];
    const [rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam] = [
      rocksStateAdapter,
      rocksStateAdapterForCompany,
      rocksStateAdapterForDepartment,
      rocksStateAdapterForUsersNotOnTeam,
    ].map((adapter, index) =>
      adapter.mapOne(
        { id: rockId, map: r => ({ ...r, milestones: r.milestones.filter(m => m._id !== milestone._id) }) },
        entityStates[index]
      )
    );
    return { ...state, rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam, milestones };
  }),
  on(
    RocksStateActions.openMilestoneCardV2InDetailView,
    (state, { milestoneId }): RockModuleStateModel => ({
      ...state,
      selectedMilestoneId: milestoneId,
      selectedRockId: null,
    })
  ),

  on(RocksStateActions.addMilestoneAttachment, (state, { id, attachment }) => {
    const milestone = state.milestones.entities[id];
    if (milestone) {
      const updatedAttachments = [...milestone.attachments, attachment];
      const milestones = milestonesStateAdapter.updateOne(
        { id, changes: { attachments: updatedAttachments } },
        state.milestones
      );
      if (state.rocksV3 && milestones.entities[id]) {
        return updateMilestoneInRock(state, milestones, milestones.entities[id].rockId, id);
      } else {
        return { ...state, milestones, loading: false };
      }
    }
    return state;
  }),

  on(RocksStateActions.removeMilestoneAttachment, (state, { id, attachmentId }) => {
    const milestone = state.milestones.entities[id];
    if (milestone?.attachments?.length) {
      const updatedAttachments = milestone.attachments.filter(attachment => attachment._id !== attachmentId);
      const milestones = milestonesStateAdapter.updateOne(
        { id, changes: { attachments: updatedAttachments } },
        state.milestones
      );
      if (state.rocksV3 && milestones.entities[id]) {
        return updateMilestoneInRock(state, milestones, milestones.entities[id].rockId, id as string);
      } else {
        return { ...state, milestones, loading: false };
      }
    }
    return state;
  }),
  //rock feature flags
  on(
    RocksStateActions.setRocksV3,
    (state, { rocksV3 }): RockModuleStateModel => ({
      ...state,
      rocksV3,
    })
  ),

  on(
    RocksStateActions.setSharedRocks,
    (state, { sharedRocksEnabled }): RockModuleStateModel => ({
      ...state,
      sharedRocksEnabled,
    })
  ),
  // end rock feature flags

  on(RocksStateActions.addAttachment, (state, { id, attachment }) => {
    const entityStates = [state.rocks, state.rocksForCompany, state.rocksForDepartment, state.rocksForUsersNotOnTeam];

    const [rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam] = [
      rocksStateAdapter,
      rocksStateAdapterForCompany,
      rocksStateAdapterForDepartment,
      rocksStateAdapterForUsersNotOnTeam,
    ].map((adapter, index) =>
      adapter.mapOne({ id: id, map: r => ({ ...r, attachments: [...r.attachments, attachment] }) }, entityStates[index])
    );
    return { ...state, rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam };
  }),
  on(RocksStateActions.removeAttachment, (state, { id, attachmentId }) => {
    const entityStates = [state.rocks, state.rocksForCompany, state.rocksForDepartment, state.rocksForUsersNotOnTeam];

    const [rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam] = [
      rocksStateAdapter,
      rocksStateAdapterForCompany,
      rocksStateAdapterForDepartment,
      rocksStateAdapterForUsersNotOnTeam,
    ].map((adapter, index) =>
      adapter.mapOne(
        {
          id: id,
          map: r => ({ ...r, attachments: r.attachments.filter(attachment => attachment._id !== attachmentId) }),
        },
        entityStates[index]
      )
    );
    return { ...state, rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam };
  }),
  on(RocksStateActions.archiveAllRocks, state => {
    const entityStates = [state.rocks, state.rocksForCompany, state.rocksForDepartment, state.rocksForUsersNotOnTeam];
    const milestones = milestonesStateAdapter.removeAll(state.milestones);

    const [rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam] = [
      rocksStateAdapter,
      rocksStateAdapterForCompany,
      rocksStateAdapterForDepartment,
      rocksStateAdapterForUsersNotOnTeam,
    ].map((adapter, index) => adapter.removeAll(entityStates[index]));

    return { ...state, rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam, milestones };
  }),
  on(RocksStateActions.updateUserOrdinalsLocal, (state, { previousIndex, currentIndex }): RockModuleStateModel => {
    const rocksToBeOrdered = _cloneDeep(rocksStateAdapter.getSelectors().selectAll(state.rocks));

    moveItemInArray(rocksToBeOrdered, previousIndex, currentIndex);
    rocksToBeOrdered.forEach((rock, i) => (rock.userOrdinal = state.pagination.index * state.pagination.size + i));

    return {
      ...state,
      rocks: rocksStateAdapter.setAll(rocksToBeOrdered, state.rocks),
    };
  }),
  on(RocksStateActions.getCompanyRocksSuccess, (state, { rocks }) => {
    const rocksForCompany = rocksStateAdapter.setAll(rocks, state.rocksForCompany);
    return { ...state, rocksForCompany };
  }),
  on(RocksStateActions.removeRockLocal, (state, { update }) => {
    return removeRockFromCurrentTeam(state, update);
  }),

  on(RocksStateActions.setArchivedFilteredList, (state, { isFilteredList }): RockModuleStateModel => {
    return { ...state, isFilteredList };
  }),

  on(RocksStateActions.setArchivedSortChanged, (state, { field, direction }): RockModuleStateModel => {
    return { ...state, archivedSortChanged: { field, direction } };
  }),

  on(RocksStateActions.setShowArchived, (state, { showArchived }): RockModuleStateModel => {
    return { ...state, showArchived };
  })
);

function updateMilestoneInRock(
  state: RockModuleStateModel,
  milestones: EntityState<Milestone>,
  rockId: string,
  milestoneId: string
) {
  const entityStates = [state.rocks, state.rocksForCompany, state.rocksForDepartment, state.rocksForUsersNotOnTeam];
  const [rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam] = [
    rocksStateAdapter,
    rocksStateAdapterForCompany,
    rocksStateAdapterForDepartment,
    rocksStateAdapterForUsersNotOnTeam,
  ].map((adapter, index) =>
    adapter.mapOne(
      {
        id: rockId,
        map: r => ({
          ...r,
          milestones: r.milestones.map(m => (m._id === milestoneId ? _cloneDeep(milestones.entities[milestoneId]) : m)),
        }),
      },
      entityStates[index]
    )
  );
  return { ...state, rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam, milestones, loading: false };
}

function removeARock(state: RockModuleStateModel, rockId: string) {
  const entityStates = [state.rocks, state.rocksForCompany, state.rocksForDepartment, state.rocksForUsersNotOnTeam];
  const [rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam] = [
    rocksStateAdapter,
    rocksStateAdapterForCompany,
    rocksStateAdapterForDepartment,
    rocksStateAdapterForUsersNotOnTeam,
  ].map((adapter, index) => adapter.removeOne(rockId, entityStates[index]));
  return {
    ...state,
    rocks,
    rocksForCompany,
    rocksForDepartment,
    rocksForUsersNotOnTeam,
    totalCount: state.totalCount - 1,
    loading: false,
  };
}

function updateRockV3(state: RockModuleStateModel, update: Update<Rock>, newRock: Rock) {
  const changes = update.changes;
  if (changes.teamId) {
    return handleTeamIdChange(state, update);
  } else if (changes.additionalTeamIds) {
    handleAdditionalTeamIdsChange(state, update, newRock);
  }
  return updateRockEntities(state, update);
}

function handleTeamIdChange(state: RockModuleStateModel, update: Update<Rock>) {
  const entityStates = [state.rocks, state.rocksForCompany, state.rocksForDepartment, state.rocksForUsersNotOnTeam];
  const rock = state.rocks.entities[update.id] || state.rocksForUsersNotOnTeam.entities[update.id];
  if (rock.teamId !== update.changes.teamId) {
    const [rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam] = [
      rocksStateAdapter,
      rocksStateAdapterForCompany,
      rocksStateAdapterForDepartment,
      rocksStateAdapterForUsersNotOnTeam,
    ].map((adapter, index) => adapter.removeOne(state.selectedRockId, entityStates[index]));
    return { ...state, rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam, loading: false };
  }
  return state;
}

function handleAdditionalTeamIdsChange(state: RockModuleStateModel, update: Update<Rock>, newRock: Rock) {
  const rock = state.rocks.entities[update.id] || state.rocksForUsersNotOnTeam.entities[update.id];
  if (!rock) {
    return addNewRock(state, newRock);
  } else if (
    rock?.teamId !== state.currentTeam._id &&
    !update.changes.additionalTeamIds.find(id => state.currentTeam._id === id)
  ) {
    return removeRockFromCurrentTeam(state, update);
  }
  return state;
}

function addNewRock(state: RockModuleStateModel, newRock: Rock) {
  const currentTeam = newRock.teamId === state.currentTeam._id;
  if (currentTeam) {
    const rocks = rocksStateAdapter.addOne(newRock, state.rocks);
    if (newRock.levelCode === RockLevelCode.companyAndDepartment || newRock.levelCode === RockLevelCode.company) {
      if (state.rocksForCompany.ids.length > 0) {
        const rocksForCompany = rocksStateAdapterForCompany.addOne(newRock, state.rocksForCompany);
        return { ...state, rocks, rocksForCompany };
      }
      const rocksForDepartment = rocksStateAdapterForDepartment.addOne(newRock, state.rocksForDepartment);
      return { ...state, rocks, rocksForDepartment };
    }
    return { ...state, rocks };
  } else {
    const rocksForUsersNotOnTeam = rocksStateAdapterForUsersNotOnTeam.addOne(newRock, state.rocksForUsersNotOnTeam);
    if (newRock.levelCode === RockLevelCode.companyAndDepartment || newRock.levelCode === RockLevelCode.company) {
      if (state.rocksForCompany.ids.length > 0) {
        const rocksForCompany = rocksStateAdapterForCompany.addOne(newRock, state.rocksForCompany);
        return { ...state, rocksForUsersNotOnTeam, rocksForCompany };
      }
      const rocksForDepartment = rocksStateAdapterForDepartment.addOne(newRock, state.rocksForDepartment);
      return { ...state, rocksForUsersNotOnTeam, rocksForDepartment };
    }
    return { ...state, rocksForUsersNotOnTeam };
  }
}

function removeRockFromCurrentTeam(state: RockModuleStateModel, update: Update<Rock>) {
  if (state.rocksForCompany.ids.length > 0) {
    const entityStates = [state.rocks, state.rocksForCompany, state.rocksForUsersNotOnTeam];
    const [rocks, rocksForCompany, rocksForUsersNotOnTeam] = [
      rocksStateAdapter,
      rocksStateAdapterForCompany,
      rocksStateAdapterForUsersNotOnTeam,
    ].map((adapter, index) => adapter.removeOne(update.id as string, entityStates[index]));
    return { ...state, rocks, rocksForUsersNotOnTeam, rocksForCompany, loading: false };
  }
  const entityStates = [state.rocks, state.rocksForDepartment, state.rocksForUsersNotOnTeam];
  const [rocks, rocksForDepartment, rocksForUsersNotOnTeam] = [
    rocksStateAdapter,
    rocksStateAdapterForDepartment,
    rocksStateAdapterForUsersNotOnTeam,
  ].map((adapter, index) => adapter.removeOne(update.id as string, entityStates[index]));
  return { ...state, rocks, rocksForUsersNotOnTeam, rocksForDepartment, loading: false };
}

function updateRockEntities(state: RockModuleStateModel, update: Update<Rock>) {
  const entityStates = [state.rocks, state.rocksForCompany, state.rocksForDepartment, state.rocksForUsersNotOnTeam];
  const [rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam] = [
    rocksStateAdapter,
    rocksStateAdapterForCompany,
    rocksStateAdapterForDepartment,
    rocksStateAdapterForUsersNotOnTeam,
  ].map((adapter, index) => adapter.updateOne(update, entityStates[index]));

  if (update.changes.levelCode) {
    const inDepartmentRockList = rocksForDepartment.entities[update.id];
    const inCompanyRockList = rocksForCompany.entities[update.id];
    const userNotOnTeam = rocksForUsersNotOnTeam.entities[update.id];

    const addRock = (adapter: EntityAdapter<Rock>, entity: Rock, stateList: EntityState<Rock>) =>
      adapter.addOne(entity, stateList);
    const removeRock = (adapter: EntityAdapter<Rock>, id: string | number, stateList: EntityState<Rock>) =>
      adapter.removeOne(id as string, stateList);

    let newRocksForDepartment = state.rocksForDepartment;
    let newRocksForCompany = state.rocksForCompany;

    switch (update.changes.levelCode) {
      case RockLevelCode.company:
        if (!inCompanyRockList) {
          newRocksForCompany = addRock(
            rocksStateAdapterForCompany,
            userNotOnTeam ? rocksForUsersNotOnTeam.entities[update.id] : rocks.entities[update.id],
            state.rocksForCompany
          );
        }
        if (inDepartmentRockList) {
          newRocksForDepartment = removeRock(rocksStateAdapterForDepartment, update.id, state.rocksForDepartment);
        }
        break;
      case RockLevelCode.department:
        if (!inDepartmentRockList) {
          newRocksForDepartment = addRock(
            rocksStateAdapterForDepartment,
            userNotOnTeam ? rocksForUsersNotOnTeam.entities[update.id] : rocks.entities[update.id],
            state.rocksForDepartment
          );
        }
        if (inCompanyRockList) {
          newRocksForCompany = removeRock(rocksStateAdapterForCompany, update.id, state.rocksForCompany);
        }
        break;
      case RockLevelCode.companyAndDepartment:
        if (!inDepartmentRockList) {
          newRocksForDepartment = addRock(
            rocksStateAdapterForDepartment,
            userNotOnTeam ? rocksForUsersNotOnTeam.entities[update.id] : rocks.entities[update.id],
            state.rocksForDepartment
          );
        }
        if (!inCompanyRockList) {
          newRocksForCompany = addRock(
            rocksStateAdapterForCompany,
            userNotOnTeam ? rocksForUsersNotOnTeam.entities[update.id] : rocks.entities[update.id],
            state.rocksForCompany
          );
        }
        break;
      default:
        if (inDepartmentRockList) {
          newRocksForDepartment = removeRock(rocksStateAdapterForDepartment, update.id, state.rocksForDepartment);
        }
        if (inCompanyRockList) {
          newRocksForCompany = removeRock(rocksStateAdapterForCompany, update.id, state.rocksForCompany);
        }
        break;
    }

    return {
      ...state,
      rocks,
      rocksForCompany: newRocksForCompany,
      rocksForDepartment: newRocksForDepartment,
      rocksForUsersNotOnTeam,
      loading: false,
    };
  }
  return { ...state, rocks, rocksForCompany, rocksForDepartment, rocksForUsersNotOnTeam, loading: false };
}

function updateRockMilestones(
  adapter: EntityAdapter<Rock>,
  rockId: string,
  milestoneId: string,
  entityState: EntityState<Rock>
) {
  return adapter.mapOne(
    { id: rockId, map: r => ({ ...r, milestones: r.milestones.filter(m => m._id !== milestoneId) }) },
    entityState
  );
}
