import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, filter, map, switchMap, tap } from 'rxjs';

import { PricingTierDialogComponent } from '../../../_components/billing-dialogs/pricing-tier-dialog/pricing-tier-dialog.component';
import { BillingV3Service } from '../../../_core/services/billing-v3.service';
import { BillingV2Service } from '../../../_core/services/billingv2.service';
import { PricingPlan } from '../../../_shared/models/billing-v3/pricing-plan';
import { getSubscriptionDetails } from '../../../_shared/models/billing-v3/subscription-details';
import { SubscriptionFrequencies } from '../../../_shared/models/billingv2/subscription-frequencies.enum';
import { PricingTier } from '../../../_shared/models/company/company-pricing-tiers';
import { selectIsTrialing } from '../../../_state/app-global/billing/billing-state.selectors';
import { selectLanguage } from '../../../_state/app-global/language/language.selectors';
import { selectCurrentUserIsManagerOrAbove } from '../../app-entities/users/users-state.selectors';
import { selectCommercialModelActive, selectTempPricingTier } from '../company/company-state.selectors';
import { SubscriptionActions } from '../company/subscription/subscription-state.actions';
import { SpinnerActions } from '../spinner/spinner-state.actions';

import { BillingStateActions } from './billing-state.actions';

@Injectable()
export class BillingStateEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly billingV2Service: BillingV2Service,
    private readonly dialog: MatDialog,
    private readonly billingV3Service: BillingV3Service,
    private readonly router: Router
  ) {}

  getSubscriptionsConfigurationOnBillingInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.billingInit),
      map(() => BillingStateActions.getActiveSubscriptionConfigurations())
    )
  );

  getCommercialModelSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.getCommercialModelSubscriptions),
      concatLatestFrom(() => [
        this.store.select(selectIsTrialing),
        this.store.select(selectLanguage),
        this.store.select(selectTempPricingTier),
      ]),
      exhaustMap(([_, isTrialing, language, currentTier]) =>
        this.billingV3Service.getCommercialModelSubscriptions().pipe(
          map(subscriptions => {
            /** overwrite current subscription until it becomes available to use in v3
             *  default to monthly subscription
             * */
            const currentSubscription = subscriptions.find(
              sub => sub.name === currentTier && sub.frequency === SubscriptionFrequencies.Monthly
            );
            const subscriptionDetails = getSubscriptionDetails(language);
            const recommendedTier =
              currentTier === PricingTier.Accelerate ? PricingTier.Thrive : PricingTier.Accelerate;
            const pricingPlans: PricingPlan[] = subscriptions.map(sub => ({
              ...sub,
              details: subscriptionDetails[sub.name],
              isCurrentPlan: !isTrialing && sub.id === currentSubscription?.id,
              isRecommended: recommendedTier === sub.name && (isTrialing || currentTier !== PricingTier.Thrive),
              isCurrentTrial: isTrialing && sub.name === currentTier,
            }));
            return BillingStateActions.getCommercialModelSubscriptionsSuccess({ pricingPlans });
          })
        )
      )
    )
  );

  getUpdatedSubscriptionOnBillingInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.billingInit),
      map(() => SubscriptionActions.getUpdatedSubscription())
    )
  );

  getCompanyBillingCountsOnBillingInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.billingInit),
      map(() => BillingStateActions.getCompanyBillingCounts())
    )
  );

  getUpcomingInvoiceOnBillingInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.billingInit),
      switchMap(() => this.store.select(selectCurrentUserIsManagerOrAbove)),
      filter(isManagerOrAbove => isManagerOrAbove),
      map(() => BillingStateActions.getUpcomingInvoice())
    )
  );

  getActiveSubscriptionConfigurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.getActiveSubscriptionConfigurations),
      exhaustMap(() =>
        this.billingV2Service.getActiveSubscriptionConfigurations().pipe(
          map(response => {
            if (!response) {
              return BillingStateActions.getActiveSubscriptionConfigurationsFailure({
                error: null,
                customMessage: 'Failed to get subscription configurations',
              });
            }
            return BillingStateActions.getActiveSubscriptionConfigurationsSuccess({
              subscriptionConfigurations: response,
            });
          })
        )
      )
    )
  );

  getCompanyBillingCounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.getCompanyBillingCounts),
      exhaustMap(() =>
        this.billingV2Service.getBillingCounts().pipe(
          map(response => {
            if (!response) {
              return BillingStateActions.getCompanyBillingCountsFailure({
                error: null,
                customMessage: 'Failed to get company billing counts',
              });
            }
            return BillingStateActions.getCompanyBillingCountsSuccess({
              companyBillingCounts: response,
            });
          })
        )
      )
    )
  );

  getUpcomingInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.getUpcomingInvoice),
      exhaustMap(() =>
        this.billingV2Service.getUpcomingInvoice().pipe(
          map(response => {
            if (!response) {
              return BillingStateActions.getUpcomingInvoiceFailure({
                error: null,
                customMessage: 'Failed to get upcoming invoice',
              });
            }
            return BillingStateActions.getUpcomingInvoiceSuccess({ invoice: response });
          })
        )
      )
    )
  );

  billingStateActionEnd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.getCompanyBillingCountsSuccess, BillingStateActions.getCompanyBillingCountsFailure),
      map(() => SpinnerActions.stopPrimary({ source: 'Subscription' }))
    )
  );

  /** New Commercial Model */
  openPricingTierDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BillingStateActions.openPricingTierDialog),
        concatLatestFrom(() => this.store.select(selectCommercialModelActive)),
        tap(([, commercialModelActive]) => {
          if (commercialModelActive)
            this.dialog.open<PricingTierDialogComponent>(PricingTierDialogComponent, {
              panelClass: 'pricing-tier-modal-container',
            });
          else this.router.navigateByUrl('settings/billing/overview');
        })
      ),
    { dispatch: false }
  );
}
