import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { trace, SpanStatusCode } from '@opentelemetry/api';
import { cloneDeep as _cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  catchError,
  filter,
  forkJoin,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs';
import { first } from 'rxjs/operators';

import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import {
  FeedbackPrintParams,
  FitCheckPrintParams,
  PrintApi,
  PrintOptions,
  PrintService,
} from '@ninety/ui/legacy/core/services/print.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { PrintDialogComponent } from '@ninety/ui/legacy/shared/components/print-dialog/print-dialog.component';
import { OrdinalUpdate } from '@ninety/ui/legacy/shared/models/_shared/ordinal-update';
import { PagedResponse } from '@ninety/ui/legacy/shared/models/_shared/paged-response';
import { Conversation, ListViewConversation } from '@ninety/ui/legacy/shared/models/feedback/conversation';
import { ConversationManagee } from '@ninety/ui/legacy/shared/models/feedback/conversation-managee';
import { ConversationMetadata } from '@ninety/ui/legacy/shared/models/feedback/conversation-metadata';
import { ConversationSideNav } from '@ninety/ui/legacy/shared/models/feedback/conversation-side-nav';
import { DefaultConversationQuestion } from '@ninety/ui/legacy/shared/models/feedback/formal-conversation-question';
import { NewConversation } from '@ninety/ui/legacy/shared/models/feedback/new-conversation';
import { Section } from '@ninety/ui/legacy/shared/models/feedback/section';
import { Rock } from '@ninety/ui/legacy/shared/models/rocks/rock';
import { PeriodIntervalScorecard } from '@ninety/ui/legacy/shared/models/scorecard/period-interval-scorecard';
import { CompanyActions } from '@ninety/ui/legacy/state/app-global/company/company-state.actions';
import { RockService } from '@ninety/web/pages/rocks/_shared/rock.service';
import { MeasurableService } from '@ninety/web/pages/scorecard/_shared/services/measurable.service';
import { TodoService } from '@ninety/web/pages/todos/_shared/todo.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  conversationsApi = '/api/v4/Conversations';

  conversations: ListViewConversation[];
  conversationsCopy: ListViewConversation[];
  conversationsCount: number;
  pastConversations: ListViewConversation[];
  pastConversationsCopy: ListViewConversation[];
  pastConversationsCount: number;
  conversationUpdate$ = new Subject();
  conversationSectionUpdate$ = new ReplaySubject<Section>(1);
  saveConversation$ = new Subject<boolean>();
  initializeSideNav$ = new ReplaySubject<ConversationSideNav>(1);
  conversationMeeting: Conversation;

  private _conversationsSubject = new BehaviorSubject<PagedResponse<ListViewConversation>>({
    items: [],
    totalCount: 0,
  });
  private _pastConversationsSubject = new BehaviorSubject<PagedResponse<ListViewConversation>>({
    items: [],
    totalCount: 0,
  });
  private _disableSidenavButtonsSubject = new BehaviorSubject(false);

  public conversations$ = this._conversationsSubject.asObservable();
  public pastConversations$ = this._pastConversationsSubject.asObservable();
  public disableSidenavButtons$ = this._disableSidenavButtonsSubject.asObservable();

  private tracer = trace.getTracer('feedback-service');

  constructor(
    private http: HttpClient,
    private filterService: FilterService,
    private spinnerService: SpinnerService,
    private errorService: ErrorService,
    public stateService: StateService,
    private rockService: RockService,
    private router: Router,
    private toastr: ToastrService,
    private todoService: TodoService,
    private measurableService: MeasurableService,
    private printService: PrintService,
    private legacyDialog: MatLegacyDialog,
    private store: Store
  ) {}

  private moveToActiveOrCompletedList(conversation: ListViewConversation, isCompleted: boolean) {
    const span = this.tracer.startSpan('FeedbackService.moveToActiveOrCompletedList', {
      attributes: {
        conversationId: conversation._id,
        isCompleted,
        currentActiveCount: this.conversationsCount,
        currentPastCount: this.pastConversationsCount,
      },
    });

    const activeConversations = isCompleted
      ? this.conversations.filter(c => c._id !== conversation._id)
      : [conversation, ...this.conversations];

    const pastConversations = !isCompleted
      ? this.pastConversations.filter(c => c._id !== conversation._id)
      : [conversation, ...this.pastConversations];

    if (isCompleted) {
      this.pastConversationsCount += 1;
      this.conversationsCount -= 1;
    } else {
      this.pastConversationsCount -= 1;
      this.conversationsCount += 1;
    }

    this.updateActiveConversationList(activeConversations);
    this.updatePastConversationList(pastConversations);

    span.setAttributes({ 'result.success': true });
    span.end();
  }

  disableSidanavButtons() {
    this._disableSidenavButtonsSubject.next(true);
  }

  enableSidenavButtons() {
    this._disableSidenavButtonsSubject.next(false);
  }

  initializeSidenav(data: ConversationSideNav | null) {
    if (data?.currentSection) {
      this.conversationSectionUpdate$.next(data.currentSection);
    }

    this.initializeSideNav$.next(data);
  }

  updateActiveConversationList(conversations: ListViewConversation[]) {
    const span = this.tracer.startSpan('FeedbackService.updateActiveConversationList', {
      attributes: {
        conversationCount: conversations.length,
        totalCount: this.conversationsCount,
      },
    });

    this.conversations = conversations;
    this._conversationsSubject.next({ items: this.conversations, totalCount: this.conversationsCount });
    this.conversationsCopy = _cloneDeep(this.conversations);

    span.setAttributes({ 'result.success': true });
    span.end();
  }

  updatePastConversationList(conversations: ListViewConversation[]) {
    const span = this.tracer.startSpan('FeedbackService.updatePastConversationList', {
      attributes: {
        conversationCount: conversations.length,
        totalCount: this.pastConversationsCount,
      },
    });

    this.pastConversations = conversations;
    this._pastConversationsSubject.next({ items: this.pastConversations, totalCount: this.pastConversationsCount });
    this.pastConversationsCopy = _cloneDeep(this.pastConversations);

    span.setAttributes({ 'result.success': true });
    span.end();
  }

  updateConversationList(isCompleted: boolean, conversations: ListViewConversation[]) {
    const span = this.tracer.startSpan('FeedbackService.updateConversationList', {
      attributes: {
        isCompleted,
        conversationCount: conversations.length,
      },
    });

    if (isCompleted) {
      this.updatePastConversationList(conversations);
    } else {
      this.updateActiveConversationList(conversations);
    }

    span.setAttributes({ 'result.success': true });
    span.end();
  }

  completeConversation(conversation: ListViewConversation, isCompleted: boolean): void {
    const span = this.tracer.startSpan('FeedbackService.completeConversation', {
      attributes: {
        conversationId: conversation._id,
        isCompleted,
      },
    });

    conversation.isCompleted = isCompleted;
    if (isCompleted) {
      conversation.isDone = true;
      if (!conversation.completedDate) conversation.completedDate = new Date();
    } else {
      conversation.isDone = conversation.isManageeDone && conversation.isManagerDone;
      if (!conversation.isDone) conversation.completedDate = null;
    }

    const update: Partial<ListViewConversation> = {
      isCompleted: conversation.isCompleted,
      isDone: conversation.isDone,
      completedDate: conversation.completedDate,
    };

    this.updateConversation(conversation._id, update)
      .pipe(
        tap(() => {
          this.moveToActiveOrCompletedList(conversation, isCompleted);
          span.setAttributes({ 'result.success': true });
        }),
        catchError((error: unknown) => {
          span.setStatus({
            code: SpanStatusCode.ERROR,
            message: error instanceof Error ? error.message : 'Unknown error',
          });
          throw error; // Let error propagate up
        })
      )
      .subscribe();

    span.end();
  }

  getById(id: string): Observable<Conversation> {
    const span = this.tracer.startSpan('FeedbackService.getById', {
      attributes: { conversationId: id },
    });

    return this.http.get<Conversation>(`${this.conversationsApi}/${id}`).pipe(
      tap(() => {
        span.setAttributes({ 'result.success': true });
        span.end();
      }),
      catchError((error: unknown) => {
        span.setStatus({
          code: SpanStatusCode.ERROR,
          message: error instanceof Error ? error.message : 'Unknown error',
        });
        span.end();
        this.router.navigate(['/1-on-1']);
        const errorMessage =
          (error as { error?: { errorMessage: string } }).error?.errorMessage ||
          `Could not get ${this.stateService.language.feedback.item}. Please try again.`;
        return this.errorService.notify(error, errorMessage, undefined, { timeOut: 10000 });
      })
    );
  }

  getConversationMetadata(id: string): Observable<ConversationMetadata> {
    const span = this.tracer.startSpan('FeedbackService.getConversationMetadata', {
      attributes: { conversationId: id },
    });

    return this.http.get<ConversationMetadata>(`${this.conversationsApi}/${id}/Metadata`).pipe(
      tap(() => {
        span.setAttributes({ 'result.success': true });
        span.end();
      }),
      catchError((error: unknown) => {
        span.setStatus({
          code: SpanStatusCode.ERROR,
          message: error instanceof Error ? error.message : 'Unknown error',
        });
        span.end();
        return this.errorService.notify(
          error,
          `Could not get ${this.stateService.language.feedback.item} metadata. Please try again.`
        );
      })
    );
  }

  getConversationMeasurables(id: string): Observable<PeriodIntervalScorecard> {
    const span = this.tracer.startSpan('FeedbackService.getConversationMeasurables', {
      attributes: { conversationId: id },
    });

    return this.getConversationMetadata(id).pipe(
      map(resp => (resp ? resp.kpiPeriods : {})),
      tap(() => {
        span.setAttributes({ 'result.success': true });
        span.end();
      }),
      catchError((error: unknown) => {
        span.setStatus({
          code: SpanStatusCode.ERROR,
          message: error instanceof Error ? error.message : 'Unknown error',
        });
        span.end();
        throw error;
      })
    );
  }

  canEdit(conversation: Conversation): void {
    if (
      conversation.manageeUserId !== this.stateService.currentUser._id &&
      conversation.managerUserId !== this.stateService.currentUser._id
    ) {
      this.toastr.error(`You cannot edit a ${this.stateService.language.feedback.item} that is not yours`, 'Oops', {
        timeOut: 10000,
      });
      this.router.navigate(['/1-on-1', conversation.type]);
    }
  }

  createConversations(
    managees: ConversationManagee[],
    conversation: Conversation,
    createTodos?: boolean
  ): Observable<Conversation[]> {
    const span = this.tracer.startSpan('FeedbackService.createConversations', {
      attributes: {
        manageeCount: managees.length,
        conversationType: conversation.type,
        createTodos: !!createTodos,
      },
    });

    const body = new NewConversation(conversation, managees, createTodos);
    return this.http.post<Conversation[]>(this.conversationsApi, body).pipe(
      tap(newConversations => {
        const selectedUserId = this.filterService.selectedUserId$.value;
        const newConversationsForUser = newConversations?.filter(
          c => selectedUserId === c.manageeUserId || selectedUserId === c.managerUserId || selectedUserId === 'all'
        );

        this.updateConversationList(false, [...newConversationsForUser, ...this.conversations]);
        this.legacyDialog.closeAll();

        span.setAttributes({
          'result.conversationCount': newConversations.length,
          'result.success': true,
        });
        span.end();
      }),
      catchError((error: unknown) => {
        span.setStatus({
          code: SpanStatusCode.ERROR,
          message: error instanceof Error ? error.message : 'Unknown error',
        });
        span.end();
        throw error;
      })
    );
  }

  updateConversation(
    id: string,
    update: Partial<Conversation>,
    metadata?: Partial<ConversationMetadata>
  ): Observable<any> {
    const span = this.tracer.startSpan('FeedbackService.updateConversation', {
      attributes: {
        conversationId: id,
        hasMetadata: !!metadata,
        updateType: Object.keys(update).join(','),
      },
    });

    const isActive = this.conversations?.find(conversation => conversation._id === id);
    const conversations = isActive ? this.conversations : this.pastConversations;
    const updateRequest = {
      ...update,
      comments: update.comments ? update.comments.filter(c => !!c.text.trim()) : [],
    };

    const requests = [this.http.put(`${this.conversationsApi}/${id}`, updateRequest)];
    if (metadata != null) {
      requests.push(this.http.put(`${this.conversationsApi}/${id}/Metadata`, metadata));
    }

    this.spinnerService.start();

    return forkJoin(requests).pipe(
      tap(() => {
        if (conversations?.length) {
          const updatedConversationIndex = conversations.findIndex(conversation => conversation._id === id);
          if (updatedConversationIndex > -1) {
            conversations[updatedConversationIndex] = Object.assign(
              conversations[updatedConversationIndex],
              updateRequest
            );
          }
        }

        this.spinnerService.stop();
        span.setAttributes({ 'result.success': true });
        span.end();
      }),
      catchError((error: unknown) => {
        this.spinnerService.stop();
        span.setStatus({
          code: SpanStatusCode.ERROR,
          message: error instanceof Error ? error.message : 'Unknown error',
        });
        span.end();
        throw error;
      })
    );
  }

  periodChange(conversation: Conversation): Observable<Rock[]> {
    const span = this.tracer.startSpan('FeedbackService.periodChange', {
      attributes: {
        conversationId: conversation._id,
        manageeUserId: conversation.manageeUserId,
        periodStartDate: conversation.periodStartDate?.toISOString(),
        periodEndDate: conversation.periodEndDate?.toISOString(),
      },
    });

    const { manageeUserId, periodStartDate, periodEndDate } = conversation;
    if (!periodStartDate || !periodEndDate) {
      span.end();
      return;
    }

    this.spinnerService.start();
    return this.rockService.getRocksForUserByDateRange(manageeUserId, periodStartDate, periodEndDate).pipe(
      mergeMap(rocks => this.updateConversation(conversation._id, { ...conversation, rocks }).pipe(map(() => rocks))),
      tap(() => {
        span.setAttributes({ 'result.success': true });
        span.end();
      }),
      catchError((error: unknown) => {
        this.spinnerService.stop();
        span.setStatus({
          code: SpanStatusCode.ERROR,
          message: error instanceof Error ? error.message : 'Unknown error',
        });
        span.end();
        return this.errorService.notify(
          error,
          `Could not update ${this.stateService.language.feedback.item}. Please try again.`
        );
      })
    );
  }

  periodChangeFormal(conversation: Conversation, updateMetadata = false): Observable<any> {
    const span = this.tracer.startSpan('FeedbackService.periodChangeFormal', {
      attributes: {
        conversationId: conversation._id,
        updateMetadata,
        periodStartDate: conversation.periodStartDate?.toISOString(),
        periodEndDate: conversation.periodEndDate?.toISOString(),
      },
    });

    const { manageeUserId, periodStartDate, periodEndDate } = conversation;
    if (!periodStartDate || !periodEndDate) {
      span.end();
      return;
    }

    this.spinnerService.start();
    return forkJoin({
      rocks: this.rockService.getRocksForUserByDateRange(manageeUserId, periodStartDate, periodEndDate),
      todosStats: this.todoService.getTodosStatsForConversation(conversation._id, periodStartDate, periodEndDate),
      measurables: this.measurableService
        .getFullScorecardForConversation(conversation._id, periodStartDate, periodEndDate)
        .pipe(first()),
    }).pipe(
      mergeMap(resp =>
        this.updateConversation(
          conversation._id,
          { ...conversation, rocks: resp.rocks, todosStats: resp.todosStats },
          updateMetadata ? { kpiPeriods: resp.measurables } : null
        ).pipe(map(() => resp))
      ),
      tap(() => {
        span.setAttributes({ 'result.success': true });
        span.end();
      }),
      catchError((error: unknown) => {
        span.setStatus({
          code: SpanStatusCode.ERROR,
          message: error instanceof Error ? error.message : 'Unknown error',
        });
        span.end();
        return this.errorService.notify(
          error,
          `Could not update ${this.stateService.language.feedback.item}. Please try again.`
        );
      })
    );
  }

  deleteConversation(conversation: Conversation): Observable<any> {
    const span = this.tracer.startSpan('FeedbackService.deleteConversation', {
      attributes: { conversationId: conversation._id },
    });

    return this.http.put(`${this.conversationsApi}/${conversation._id}`, { isDeleted: true }).pipe(
      tap(() => {
        span.setAttributes({ 'result.success': true });
        span.end();
      }),
      catchError((error: unknown) => {
        span.setStatus({
          code: SpanStatusCode.ERROR,
          message: error instanceof Error ? error.message : 'Unknown error',
        });
        span.end();
        return this.errorService.notify(
          error,
          `Could not delete ${this.stateService.language.feedback.item}. Please try again.`
        );
      })
    );
  }

  updateDefaultQuestions(questions: DefaultConversationQuestion[]): Observable<void> {
    const span = this.tracer.startSpan('FeedbackService.updateDefaultQuestions', {
      attributes: { questionCount: questions.length },
    });

    this.store.dispatch(CompanyActions.updateCompanySetting({ settings: { defaultConversationQuestions: questions } }));
    const update = { defaultConversationQuestions: questions };

    return this.http.put<void>(`${this.conversationsApi}/DefaultQuestions`, update).pipe(
      tap(() => {
        span.setAttributes({ 'result.success': true });
        span.end();
      }),
      catchError((error: unknown) => {
        span.setStatus({
          code: SpanStatusCode.ERROR,
          message: error instanceof Error ? error.message : 'Unknown error',
        });
        span.end();
        return this.errorService.notify(error, 'Could not update default questions. Please try again.');
      })
    );
  }

  updateConversationQuestions(conversationId: string, questions: DefaultConversationQuestion[]): Observable<void> {
    const span = this.tracer.startSpan('FeedbackService.updateConversationQuestions', {
      attributes: {
        conversationId,
        questionCount: questions.length,
      },
    });

    const update = { meetingQuestions: questions };
    return this.http.put<void>(`${this.conversationsApi}/${conversationId}/UpdateQuestions`, update).pipe(
      tap(() => {
        span.setAttributes({ 'result.success': true });
        span.end();
      }),
      catchError((error: unknown) => {
        span.setStatus({
          code: SpanStatusCode.ERROR,
          message: error instanceof Error ? error.message : 'Unknown error',
        });
        span.end();
        return this.errorService.notify(error, 'Could not update questions. Please try again.');
      })
    );
  }

  updateOrdinals(updateOnly = false, offset = 0, isActiveConversationList = true) {
    const span = this.tracer.startSpan('FeedbackService.updateOrdinals', {
      attributes: {
        updateOnly,
        offset,
        isActiveConversationList,
        conversationCount: (isActiveConversationList ? this.conversations : this.pastConversations)?.length,
      },
    });

    const conversations = isActiveConversationList ? this.conversations : this.pastConversations;
    conversations.forEach((c: Conversation, i) => (c.ordinal = i + offset));
    const models: OrdinalUpdate[] = conversations.map(
      (t: Conversation, i: number) => new OrdinalUpdate(t._id, i + offset)
    );

    if (!updateOnly) this.conversationUpdate$.next(null);

    this.http
      .put<any>(`${this.conversationsApi}/Ordinals`, { models })
      .pipe(
        tap(() => {
          span.setAttributes({ 'result.success': true });
          span.end();
        }),
        catchError((error: unknown) => {
          span.setStatus({
            code: SpanStatusCode.ERROR,
            message: error instanceof Error ? error.message : 'Unknown error',
          });
          span.end();
          throw error;
        })
      )
      .subscribe();
  }

  setConversationTitle(title?: string): void {
    const span = this.tracer.startSpan('FeedbackService.setConversationTitle', {
      attributes: { hasTitle: !!title },
    });

    this.stateService.setTitle(`${this.stateService.language.feedback.route} Meeting${title ? '- ' + title : ''}`);
    span.setAttributes({ 'result.success': true });
    span.end();
  }

  openPrintDialog(conversation: ListViewConversation) {
    const span = this.tracer.startSpan('FeedbackService.openPrintDialog', {
      attributes: { conversationId: conversation._id },
    });

    this.legacyDialog
      .open(PrintDialogComponent)
      .afterClosed()
      .pipe(
        filter(printOptions => !!printOptions),
        switchMap((printOptions: PrintOptions) =>
          this.printService.openPdf<FeedbackPrintParams>(PrintApi.feedback, {
            conversationId: conversation._id,
            printOptions,
          })
        ),
        tap(() => {
          span.setAttributes({ 'result.success': true });
          span.end();
        }),
        catchError((error: unknown) => {
          span.setStatus({
            code: SpanStatusCode.ERROR,
            message: error instanceof Error ? error.message : 'Unknown error',
          });
          span.end();
          throw error;
        })
      )
      .subscribe();
  }

  openFitCheckPrintDialog(userId: string) {
    const span = this.tracer.startSpan('FeedbackService.openFitCheckPrintDialog', {
      attributes: { userId },
    });

    this.legacyDialog
      .open(PrintDialogComponent, {
        data: {
          isLandscapeDefault: true,
        },
      })
      .afterClosed()
      .pipe(
        filter(printOptions => !!printOptions),
        switchMap((printOptions: PrintOptions) =>
          this.printService.openPdf<FitCheckPrintParams>(PrintApi.fitCheck, {
            userId: userId,
            printOptions,
          })
        ),
        tap(() => {
          span.setAttributes({ 'result.success': true });
          span.end();
        }),
        catchError((error: unknown) => {
          span.setStatus({
            code: SpanStatusCode.ERROR,
            message: error instanceof Error ? error.message : 'Unknown error',
          });
          span.end();
          throw error;
        })
      )
      .subscribe();
  }
}
