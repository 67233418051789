import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import isEmpty from 'lodash/isEmpty';

import { TerraIconModule } from '@ninety/terra';

import { ButtonComponent } from '../../../_components/buttons/button/button.component';
import { ButtonRowComponent } from '../../../_components/buttons/button-row/button-row.component';
import { HelperService } from '../../../_core/services/helper.service';
import { IdentityProviderService } from '../../../_core/services/identity-provider.service';
import { NotifyService } from '../../../_core/services/notify.service';
import { SpinnerService } from '../../../_core/services/spinner.service';
import {
  ForgotPasswordDialogComponent,
  ForgotPasswordDialogData,
} from '../forgot-password-dialog/forgot-password-dialog.component';

type AccountChangePasswordValidationErrors = {
  missingOldPassword?: true;
  missingNewPassword?: true;
  missingNewConfirmPassword?: true;
  passwordMismatch?: true;
  passwordLength?: true;
  missingLowerAlpha?: true;
  missingNumeric?: true;
  missingSpecial?: true;
  invalidChars?: string;
} & ValidationErrors;

export interface AccountChangePasswordDialogData {
  email?: string; //used in case Forgot Password is triggered from the Change Password dialog
  isTempPassword?: false;
}

@Component({
  selector: 'ninety-account-change-password-dialog',
  templateUrl: './account-change-password-dialog.component.html',
  styleUrls: ['./account-change-password-dialog.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatSnackBarModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    ButtonComponent,
    ButtonRowComponent,
    TerraIconModule,
  ],
})
export class AccountChangePasswordDialogComponent implements OnDestroy {
  oldPasswordControl = new UntypedFormControl('', this.data.isTempPassword ? null : [Validators.required]);
  newPasswordControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(HelperService.Regex.validAwsPassword),
  ]);
  confirmNewPasswordControl = new UntypedFormControl('', Validators.required);
  accountChangePasswordGroup = new UntypedFormGroup(
    {
      oldPasswordControl: this.oldPasswordControl,
      newPasswordControl: this.newPasswordControl,
      confirmNewPasswordControl: this.confirmNewPasswordControl,
    },
    {
      validators: this.data.isTempPassword
        ? this.validateChangeTempPasswordGroup
        : this.validateAccountChangePasswordGroup,
    }
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AccountChangePasswordDialogData,
    public accountChangePasswordDialogRef: MatDialogRef<AccountChangePasswordDialogComponent, boolean | string>,
    public forgotPasswordDialogRef: MatDialogRef<ForgotPasswordDialogComponent, boolean>,
    public spinnerService: SpinnerService,
    private idService: IdentityProviderService,
    private notifyService: NotifyService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.accountChangePasswordGroup.reset({
      oldPasswordControl: '',
      newPasswordControl: '',
      confirmPasswordControl: '',
    });
  }

  accountChangePasswordSubmit() {
    this.spinnerService.start();
    this.idService.changePassword(this.oldPasswordControl.value, this.newPasswordControl.value).subscribe({
      next: () => {
        this.spinnerService.stop();
        this.accountChangePasswordDialogRef.close(true);
        this.notifyService.notify('Password successfully changed!', 5000);
      },
      error: (err: unknown) => {
        this.spinnerService.stop();
        // console.error(err);
        this.notifyService.showError('Failed to set new password. Please try password reset again.');
      },
    });
  }

  changeTempPasswordSubmit() {
    this.spinnerService.start();
    this.idService.completeNewPassword(this.newPasswordControl.value).subscribe({
      next: () => {
        this.spinnerService.stop();
        this.accountChangePasswordDialogRef.close(this.newPasswordControl.value);
        this.notifyService.notify('New password saved', 5000);
      },
      error: (err: unknown) => {
        // console.error(err);
        this.notifyService.showError('Failed to set new password. Please try again.');
      },
    });
  }

  openForgotPasswordDialog(): void {
    this.accountChangePasswordDialogRef.close();

    this.dialog
      .open<ForgotPasswordDialogComponent, ForgotPasswordDialogData, void>(ForgotPasswordDialogComponent, {
        width: '500px',
        disableClose: true,
        data: { email: this.data.email, disableEmailEdit: true },
      })
      .afterClosed()
      .subscribe();
  }

  validateAccountChangePasswordGroup(
    accountChangePasswordGroup: UntypedFormGroup
  ): null | AccountChangePasswordValidationErrors {
    const oldPassword: string = accountChangePasswordGroup.get('oldPasswordControl').value;
    const newPassword: string = accountChangePasswordGroup.get('newPasswordControl').value;
    const newConfirmPassword: string = accountChangePasswordGroup.get('confirmNewPasswordControl').value;
    const errors = Object.create(null);

    if (!oldPassword) errors.oldPassword = true;

    if (newPassword) {
      if (newConfirmPassword && newPassword !== newConfirmPassword) errors.passwordMismatch = true;
      if (newPassword.length < 8) errors.passwordLength = true;
      if (!HelperService.Regex.hasLowerAlpha.test(newPassword)) errors.missingLowerAlpha = true;
      if (!HelperService.Regex.hasNumeric.test(newPassword)) errors.missingNumeric = true;
      if (!HelperService.Regex.hasAwsPasswordSpecialChar.test(newPassword)) errors.missingSpecial = true;
      const invalidCharSet = new Set(newPassword.match(HelperService.Regex.matchAwsInvalidPasswordChar));
      if (invalidCharSet.size > 0) errors.invalidChars = Array.from(invalidCharSet).join('');
    }

    return isEmpty(errors) ? null : errors;
  }

  // can't access component state within these validators, so just make a second one that pivots on isTempPassword
  validateChangeTempPasswordGroup(
    accountChangePasswordGroup: UntypedFormGroup
  ): null | AccountChangePasswordValidationErrors {
    const newPassword: string = accountChangePasswordGroup.get('newPasswordControl').value;
    const newConfirmPassword: string = accountChangePasswordGroup.get('confirmNewPasswordControl').value;
    const errors = Object.create(null);

    if (newPassword) {
      if (newConfirmPassword && newPassword !== newConfirmPassword) errors.passwordMismatch = true;
      if (newPassword.length < 8) errors.passwordLength = true;
      if (!HelperService.Regex.hasLowerAlpha.test(newPassword)) errors.missingLowerAlpha = true;
      if (!HelperService.Regex.hasNumeric.test(newPassword)) errors.missingNumeric = true;
      if (!HelperService.Regex.hasAwsPasswordSpecialChar.test(newPassword)) errors.missingSpecial = true;
      const invalidCharSet = new Set(newPassword.match(HelperService.Regex.matchAwsInvalidPasswordChar));
      if (invalidCharSet.size > 0) errors.invalidChars = Array.from(invalidCharSet).join('');
    }

    return isEmpty(errors) ? null : errors;
  }
}
