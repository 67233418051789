/* eslint @ngrx/prefer-effect-callback-in-block-statement: off */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, map, mergeMap, tap } from 'rxjs';

import { AddressValidatorComponent } from '../../../../_components/billing-dialogs/address-validator/address-validator.component';
import { ManageSeatsComponent } from '../../../../_components/billing-dialogs/manage-seats/manage-seats.component';
import { BillingV2Service } from '../../../../_core/services/billingv2.service';
import { BannerNotificationActions } from '../../banner-notification/banner-notification.actions';
import { BannerNotificationModel } from '../../banner-notification/banner-notification.model';
import { BillingStateActions } from '../../billing/billing-state.actions';
import { SpinnerActions } from '../../spinner/spinner-state.actions';

import { SubscriptionActions } from './subscription-state.actions';
import { selectBanner } from './subscription-state.selectors';

@Injectable()
export class SubscriptionStateEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private billingV2Service: BillingV2Service,
    private dialog: MatDialog,
    private router: Router
  ) {}

  getUpdatedSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.getUpdatedSubscription),
      exhaustMap(() =>
        this.billingV2Service.getUpdatedSubscription().pipe(
          map(response => {
            if (!response.provider) {
              return SubscriptionActions.getUpdatedSubscriptionFailure({
                error: null,
                customMessage: 'Failed to get subscription',
              });
            }
            return SubscriptionActions.getUpdatedSubscriptionSuccess({ subscription: response });
          })
        )
      )
    )
  );

  requestUpdateSubscriptionUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.requestUpdateSubscriptionUrl),
      exhaustMap(() =>
        this.billingV2Service.getSubscriptionUpdateUrl().pipe(
          map(response => {
            if (!response.redirectUrl) {
              return SubscriptionActions.requestUpdateSubscriptionUrlFailure({
                error: null,
                customMessage: 'Failed to get subscription update url',
              });
            }
            return SubscriptionActions.requestUpdateSubscriptionUrlSuccess({ redirectUrl: response.redirectUrl });
          })
        )
      )
    )
  );

  requestPaymentMethodRedirectUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.requestPaymentMethodUpdateUrl),
      exhaustMap(({ customerBillingInfo }) =>
        this.billingV2Service.getUpdatePaymentMethodUrl(customerBillingInfo).pipe(
          map(response => {
            if (!response.redirectUrl) {
              return SubscriptionActions.requestPaymentMethodUpdateUrlFailure({
                error: null,
                customMessage: 'Failed to get update payment method url',
              });
            }
            return SubscriptionActions.requestPaymentMethodUpdateUrlSuccess({ redirectUrl: response.redirectUrl });
          })
        )
      )
    )
  );

  requestResumeSubscriptionUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.requestResumeSubscriptionUrl),
      exhaustMap(({ customerBillingInfo }) =>
        this.billingV2Service.getResumeSubscriptionUrl(customerBillingInfo).pipe(
          map(response => {
            if (!response.redirectUrl) {
              return SubscriptionActions.requestResumeSubscriptionUrlFailure({
                error: null,
                customMessage: 'Failed to get resume subscription url',
              });
            }
            return SubscriptionActions.requestResumeSubscriptionUrlSuccess({ redirectUrl: response.redirectUrl });
          })
        )
      )
    )
  );

  requestReactivateSubscriptionUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.requestReactivateSubscriptionUrl),
      exhaustMap(() =>
        this.billingV2Service.getSubscriptionReactivateUrl().pipe(
          map(response => {
            if (!response.redirectUrl) {
              return SubscriptionActions.requestReactivateSubscriptionUrlFailure({
                error: null,
                customMessage: 'Failed to get reactivate subscription url',
              });
            }
            return SubscriptionActions.requestReactivateSubscriptionUrlSuccess({ redirectUrl: response.redirectUrl });
          })
        )
      )
    )
  );

  requestCancelSubscriptionUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.requestCancelSubscriptionUrl),
      exhaustMap(() =>
        this.billingV2Service.getSubscriptionCancelUrl().pipe(
          map(response => {
            if (!response.redirectUrl) {
              return SubscriptionActions.requestCancelSubscriptionUrlFailure({
                error: null,
                customMessage: 'Failed to get cancel subscription url',
              });
            }
            return SubscriptionActions.requestCancelSubscriptionUrlSuccess({ redirectUrl: response.redirectUrl });
          })
        )
      )
    )
  );

  requestBillingPortalUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.requestBillingPortalUrl),
      exhaustMap(() =>
        this.billingV2Service.getBillingPortalUrl().pipe(
          map(response => {
            if (!response.redirectUrl) {
              return SubscriptionActions.requestBillingPortalUrlFailure({
                error: null,
                customMessage: 'Failed to get billing portal url',
              });
            }
            return SubscriptionActions.requestBillingPortalUrlSuccess({ redirectUrl: response.redirectUrl });
          })
        )
      )
    )
  );

  requestUpdateSubscriptionQuantityUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.requestUpdateSubscriptionQuantityUrl),
      exhaustMap(({ updateSubscriptionQuantityRequest }) =>
        this.billingV2Service.getSubscriptionQuantityUpdateUrl(updateSubscriptionQuantityRequest).pipe(
          map(response => {
            if (!response.redirectUrl) {
              return SubscriptionActions.requestUpdateSubscriptionQuantityUrlFailure({
                error: null,
                customMessage: 'Failed to update subscription seats',
              });
            }
            return SubscriptionActions.requestUpdateSubscriptionQuantityUrlSuccess({
              redirectUrl: response.redirectUrl,
            });
          })
        )
      )
    )
  );

  private openManageSeatsDialog() {
    this.dialog
      .open(ManageSeatsComponent, {
        width: '600px',
        minHeight: '350px',
        autoFocus: '#standardSeatCountInput',
        disableClose: true,
      })
      .afterClosed();
  }

  openManageSeatsDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SubscriptionActions.openManageSeatsDialog),
        tap(() => this.openManageSeatsDialog())
      ),
    { dispatch: false }
  );

  goToBillingManageSeats$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SubscriptionActions.goToBillingManageSeats),
        tap(() => this.router.navigateByUrl('settings/billing/overview').then(() => this.openManageSeatsDialog()))
      ),
    { dispatch: false }
  );

  checkForBanners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.checkForBanners, SubscriptionActions.getUpdatedSubscriptionSuccess),
      mergeMap(() => {
        return this.store.select(selectBanner).pipe(
          map((banner: BannerNotificationModel | null) => {
            if (banner) {
              return BannerNotificationActions.displayBanner({ banner: banner });
            } else {
              return BannerNotificationActions.noBanner();
            }
          })
        );
      })
    )
  );

  autoIncrementSubscriptionQuantity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.autoIncrementSubscriptionQuantity),
      exhaustMap(({ quantity }) =>
        this.billingV2Service.autoIncrementSubscriptionQuantity(quantity).pipe(
          map(response => {
            if (!response.provider) {
              return SubscriptionActions.autoIncrementSubscriptionQuantityFailure({
                error: null,
                customMessage: 'Failed to auto increment seats',
              });
            }
            return SubscriptionActions.autoIncrementSubscriptionQuantitySuccess({ subscription: response });
          })
        )
      )
    )
  );

  autoIncrementSubscriptionQuantityBillingPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.autoIncrementSubscriptionQuantityBillingPage),
      exhaustMap(({ quantity }) =>
        this.billingV2Service.autoIncrementSubscriptionQuantity(quantity).pipe(
          map(response => {
            if (!response.provider) {
              return SubscriptionActions.autoIncrementSubscriptionQuantityBillingPageFailure({
                error: null,
                customMessage: 'Failed to auto increment seats',
              });
            }
            return SubscriptionActions.autoIncrementSubscriptionQuantityBillingPageSuccess({ subscription: response });
          })
        )
      )
    )
  );

  autoIncrementSubscriptionQuantityBillingPageComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SubscriptionActions.autoIncrementSubscriptionQuantityBillingPageSuccess,
        SubscriptionActions.autoIncrementSubscriptionQuantityBillingPageFailure
      ),
      tap(() => this.dialog.closeAll()),
      map(() => BillingStateActions.billingInit())
    )
  );

  redirectToBillingPortalUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SubscriptionActions.requestUpdateSubscriptionUrlSuccess,
          SubscriptionActions.requestPaymentMethodUpdateUrlSuccess,
          SubscriptionActions.requestResumeSubscriptionUrlSuccess,
          SubscriptionActions.requestReactivateSubscriptionUrlSuccess,
          SubscriptionActions.requestCancelSubscriptionUrlSuccess,
          SubscriptionActions.requestBillingPortalUrlSuccess,
          SubscriptionActions.requestUpdateSubscriptionQuantityUrlSuccess
        ),
        tap(({ redirectUrl }) => {
          window.location.href = redirectUrl;
        })
      ),
    { dispatch: false }
  );

  subscriptionActionStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SubscriptionActions.requestPaymentMethodUpdateUrl,
        SubscriptionActions.requestUpdateSubscriptionUrl,
        SubscriptionActions.requestResumeSubscriptionUrl,
        SubscriptionActions.requestReactivateSubscriptionUrl,
        SubscriptionActions.requestCancelSubscriptionUrl,
        SubscriptionActions.getUpdatedSubscription,
        SubscriptionActions.requestBillingPortalUrl,
        SubscriptionActions.requestUpdateSubscriptionQuantityUrl,
        SubscriptionActions.openManageSeatsDialog,
        SubscriptionActions.autoIncrementSubscriptionQuantityBillingPage
      ),
      map(() => SpinnerActions.startPrimary({ source: 'Subscription' }))
    )
  );

  subscriptionActionEnd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SubscriptionActions.requestPaymentMethodUpdateUrlFailure,
        SubscriptionActions.requestUpdateSubscriptionUrlFailure,
        SubscriptionActions.requestResumeSubscriptionUrlFailure,
        SubscriptionActions.requestReactivateSubscriptionUrlFailure,
        SubscriptionActions.requestUpdateSubscriptionQuantityUrlFailure,
        SubscriptionActions.requestCancelSubscriptionUrlFailure,
        SubscriptionActions.getUpdatedSubscriptionSuccess,
        SubscriptionActions.getUpdatedSubscriptionFailure,
        SubscriptionActions.requestBillingPortalUrlFailure,
        SubscriptionActions.requestUpdateSubscriptionQuantityUrlFailure,
        SubscriptionActions.autoIncrementSubscriptionQuantityBillingPageSuccess,
        SubscriptionActions.autoIncrementSubscriptionQuantityBillingPageFailure
      ),
      map(() => SpinnerActions.stopPrimary({ source: 'Subscription' }))
    )
  );

  openValidateAddressDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.openValidateAddressDialog),
      exhaustMap(({ billingRedirectReasons }) =>
        this.dialog
          .open(AddressValidatorComponent, {
            width: '600px',
            minHeight: '280px',
            autoFocus: '#country',
            disableClose: false,
            data: {
              billingRedirectReasons,
            },
          })
          .afterClosed()
          .pipe(
            map(result => {
              if (result) {
                return SubscriptionActions.openValidateAddressDialogSuccess();
              } else {
                return SubscriptionActions.openValidateAddressDialogFailure({
                  error: null,
                  customMessage: 'Failed to validate address',
                });
              }
            })
          )
      )
    )
  );
}
