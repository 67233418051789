import { OverlayContainer } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnInit,
  TrackByFunction,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { LetDirective, PushPipe } from '@ngrx/component';
import { distinctUntilChanged, skip } from 'rxjs';

import { TerraQuickFilterModule, TerraSearchInputModule } from '@ninety/terra';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { teamAll } from '@ninety/ui/legacy/state/app-entities/team-list/api/team-list.model';

import { SelectImplementationDirective } from '../chip-select/directives/select-implementation.directive';
import { ManagedOptions } from '../chip-select/services/fuse-search.service.model';
import { FuseTeamDirective } from '../chip-select/services/fuse-team.directive';

/* eslint-disable @angular-eslint/no-outputs-metadata-property,@angular-eslint/no-output-rename */

@Component({
  selector: 'ninety-team-quick-filter',
  standalone: true,
  imports: [CommonModule, PushPipe, LetDirective, FormsModule, TerraSearchInputModule, TerraQuickFilterModule],
  templateUrl: './team-quick-filter.component.html',
  styleUrls: ['./team-quick-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    {
      directive: SelectImplementationDirective,
      inputs: ['readonly', 'disabled', 'id', 'noResultsText', 'selected: selectedTeam'],
      outputs: ['selectedChange: selectedTeamChange'],
    },
    {
      directive: FuseTeamDirective,
      inputs: ['ninetyFuseTeam: teams$', 'ninetyFuseTeamOptions: fuseOptions'],
    },
  ],
})
export class TeamQuickFilterComponent implements OnInit, AfterViewInit {
  /**
   * Whether to include the "TeamAll" option in the team select.
   *
   * Most pages do not have a team all besides My90 & Directory, so the default is false.
   *
   * Pass `[shouldShowTeamAll]=true` to enable the option.
   */
  @Input() shouldShowTeamAll = false;

  protected readonly trackBy: TrackByFunction<ManagedOptions<Team>> = (_index, item) => item.value._id;

  protected optionListElement: Element;

  protected _teamSearchInput = '';
  protected _selectedQfTeam: Team;

  constructor(
    protected readonly select: SelectImplementationDirective<Team>,
    protected readonly fuseProvider: FuseTeamDirective,
    private readonly _destroyRef: DestroyRef,
    private readonly _overlayContainer: OverlayContainer
  ) {
    select.multiple = false;
  }

  ngOnInit() {
    this._selectedQfTeam = this.select.selected[0];
  }

  ngAfterViewInit() {
    this.fuseProvider.fuse.managedOptions$
      .pipe(distinctUntilChanged(), skip(1), takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        this.optionListElement.scrollTo({ top: 0, behavior: 'smooth' });
      });
  }

  protected search(query: string) {
    this.fuseProvider.fuse.search({ query });
  }

  protected onSelectValue($event: Team) {
    this._selectedQfTeam = $event;
    const team = [$event];
    this.select.updateObserversOnValueChange(team);
  }

  protected onOpenChange(isOpen: boolean) {
    if (!isOpen) {
      this.fuseProvider.fuse.search({ query: null });
      this._teamSearchInput = '';
    } else {
      // TERRATODO: Temp until scrollToTop() available
      if (!this.optionListElement) {
        const overlayContainerElement = this._overlayContainer.getContainerElement();
        this.optionListElement = overlayContainerElement.querySelector('.terra-select__options');
      }
    }
  }

  protected _teamsAreSame(option: Team, value: Team): boolean {
    return option?._id === value?._id;
  }

  protected _handleSearchTermChanged($event: string) {
    this._teamSearchInput = $event;
    this.search(this._teamSearchInput);
  }

  protected readonly teamAll = teamAll;
}
